<!-- Slider -->
<div id="slider" class="slider-element min-vh-0">
    <div class="container">
        <div class="row justify-content-between align-items-center align-items-xl-end">
            <div class="col-lg-6 col-xxl-5 py-5 py-lg-6 my-lg-5 text-center text-lg-start">
                <h1 class="display-4 fw-bolder ls-n-1">LANCE <span style="background-color: #ffd600; color: black;">AUJOURD'HUI</span> TON BUSINESS</h1>
                <p class="lead fw-normal mb-5">Découvre comment l'intelligence artificielle peut être ta plus grande alliée pour définir, optimiser et accélérer ton Business</p>
                <!-- <a routerLink="/business-1/simulateur/etape/1" class="button button-large px-lg-5 py-lg-3 rounded-pill m-0 mb-4"><i class="fa-solid fa-star"></i>Démarre ton analyse</a> -->
                <a routerLink="/business-1/simulateur/etape/0" class="button button-large button-circle fw-bolder pointer"><i class="fa-solid fa-business-time"></i> Lance ton business</a>
            </div>
            <div class="col-lg-6 col-xxl-7">
                <img src="assets/images/hero.svg" alt="...">
            </div>
        </div>
    </div>
</div><!-- #slider end -->

<!-- Content -->
<section id="content">
    <div class="content-wrap py-0">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-lg-4 d-none d-lg-block">
                        <p class="fw-bolder mb-0">+250 outils IA à ton service —</p>
                    </div>

                    <div class="col-lg-8">
                        <div class="row g-4 align-items-center justify-content-center">               
                            <div class="col-md-2 col-4 flex-fill text-center op-08"><img height="90" src="assets/images/simulator/gpt-icon.jpg" alt="Brands"></div>
                            <div class="col-md-2 col-4 flex-fill text-center op-08"><img height="90" src="assets/images/simulator/midjourney-icon-01.png" alt="Brands"></div>                            
                            <div class="col-md-2 col-4 flex-fill text-center op-08"><img height="100" src="assets/images/simulator/jas-icon.jpg" alt="Brands"></div>
                            <div class="col-md-2 col-4 flex-fill text-center op-08"><img height="90" src="assets/images/simulator/tab-icon.jpg" alt="Brands"></div>
                            <div class="col-md-2 col-4 flex-fill text-center op-08"><img height="50" src="assets/images/simulator/stability-ai.png" alt="Brands"></div>                                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="section-examples" class="section page-section dark bg-black">
            <div class="container z-3">
                <div class="position-md-absolute start-0 top-0 w-100 h-100">
                    <div class="row">
                        <div class="col-xl-7 col-lg-10">
                            <span class="text-uppercase small ls-4 text-contrast-500 mb-3 d-inline-block">Découvre notre approche</span>
                            <h3 class="fs-1 mb-4">Des stratégies basées sur l'IA pour des décisions business plus intelligentes et efficaces.</h3>

                            <ul class="list-inline">
                                <li class="list-inline-item small me-4"><i class="fa-solid fa-check me-1"></i> Insights basés sur des données réelles</li>
                                <li class="list-inline-item small me-4"><i class="fa-solid fa-check me-1"></i> Solutions sur mesure</li>
                                <li class="list-inline-item small"><i class="fa-solid fa-check me-1"></i> Adapté à tous les secteurs</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <img class="d-none d-md-block z-2 position-relative" src="assets/images/simulator/cards-bg-ia.png" alt="..."> -->
            <img class="z-2 position-relative" src="assets/images/simulator/cards-bg-ia.png" alt="...">
            
        </div>
        
    </div>
</section>

<section>
    <div class="content-wrap py-10">
        <div class="page-section">
            <div class="container">
                <div class="clear"></div>
                <div class="text-center">       
                    <h3 class="mb-0 no-color fw-bolder mb-4"><span
                        style="background-color: #ffd600; color: #000000;">Intelligence Artificielle, No-Code & Automations </span><br>
                        

                        #ExtremeTechnologie </h3>                                                                                           
                    <img src="assets/images/simulator/etp-brand-01.png" class="mb-5 pt-3">
                    <br>                  
                    <a routerLink="/business-1/simulateur/etape/1" class="button button-large button-circle fw-bolder pointer"><i class="fa-solid fa-business-time"></i> Lance ton business</a>
                </div>
            </div>
        </div>
    </div>
</section>


<app-simulator-footer></app-simulator-footer>
