<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-8 col-lg-12">
                        <div class="row g-4 align-items-center justify-content-center">
                            <div id="tab-reservation" class="nav nav-pills flex-column flex-lg-row" role="tablist">
                                <a class="nav-link active ms-0 mt-2 mt-lg-0 ms-lg-3  fw-bolder"
                                    routerLink="/simulator/1">1. Type de business</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="content" class="pt-5">
    <div class="content-wrap py-10">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative">1 / Votre <span
                            class="gradient-text gradient-color">Business</span> existe ?</h3>
                    <p class="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                        doloremque laudantium,</p>
                    <!-- <a href="#" class="button button-border button-large rounded-pill m-0">Etape 8 - Type de business ?</a> -->
                </div>
                <div class="row align-items-stretch g-4 center mb-5">

                    <!-- <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [class.selected-border]="cardSelected"  (click)="toggleCardSelection()">
                                <h4 class="mb-3 fw-bolder tile-question">J'ai passer la phase de l'idéation </h4>
                                <p class="mb-3 text-muted">Lorem ipsum dolor sit amet, consectetur</p>                                
                                <a (click)="navigateStepTwo()" class="button button-large button-circle fw-bolder" style="background-color: #FFD600; color: black;"><i class="fa-solid fa-check"></i>Sélectionner</a>      
                            </div>
                        </div>
                    </div> 
                                                             
                    <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer">
                                <h4 class="mb-3 fw-bolder tile-question">J'ai l'idée mais pas encore lancé</h4>
                                <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>                              
                                <a (click)="navigateStepTwo()" class="button button-large button-circle fw-bolder" style="background-color: #FFD600; color: black;"><i class="fa-solid fa-check"></i>Sélectionner</a>
                            </div>
                        </div>
                    </div>       -->                    
                    <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [class.selected-border]="session.step1.response === '1'"
                            [ngStyle]="{'background-color': isCardSelected01 ? '#ffd600' : '#F0F1F2'}"
                                (click)="navigateStepTwo('1')">
                                <h4 class="mb-3 fw-bolder tile-question">J'ai passer la phase de l'idéation </h4>
                                <p class="mb-3 text-muted">Lorem ipsum dolor sit amet, consectetur</p>
                                <a class="button button-large button-circle fw-bolder" [ngStyle]="{'background-color': isCardSelected01 ? '#ffd600' : '#FFD600; color:black;'}">
                                    <i *ngIf="session.step1.response === '1'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- <a class="button button-large button-circle fw-bolder" style="background-color: #FFD600; color: black;"> -->

                    <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [class.selected-border]="session.step1.response === '2'"
                            [ngStyle]="{'background-color': isCardSelected02 ? '#ffd600' : '#F0F1F2'}"
                                (click)="navigateStepTwo('2')">
                                <h4 class="mb-3 fw-bolder tile-question">J'ai l'idée mais pas encore lancé</h4>
                                <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>
                                <a class="button button-large button-circle fw-bolder"
                                    style="background-color: #FFD600; color: black;">
                                    <i *ngIf="session.step1.response === '2'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [class.selected-border]="selectedCardId === 'card1'"
                                (click)="navigateStepTwo('card1')">
                                <h4 class="mb-3 fw-bolder tile-question">J'ai passer la phase de l'idéation </h4>
                                <p class="mb-3 text-muted">Lorem ipsum dolor sit amet, consectetur</p>
                                <a class="button button-large button-circle fw-bolder"
                                    style="background-color: #FFD600; color: black;">
                                    <i *ngIf="selectedCardId === 'card1'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [class.selected-border]="selectedCardId === 'card2'"
                                (click)="navigateStepTwo('card2')">
                                <h4 class="mb-3 fw-bolder tile-question">J'ai l'idée mais pas encore lancé</h4>
                                <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>
                                <a class="button button-large button-circle fw-bolder"
                                    style="background-color: #FFD600; color: black;">
                                    <i *ngIf="selectedCardId === 'card2'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                        aria-valuemax="100" style="width: 12.5%;">
                    </div>
                </div>
                <!-- <div class="row align-items-center gy-3">                    
                    <div class="col-lg-12">
                        <div class="skill-progress mb-4" data-percent="10" data-speed="2000" style="--cnvs-progress-height: 36px; --cnvs-progress-rounded: 50rem;">
                            <div class="skill-progress-bar">
                                <div class="skill-progress-percent" style="background-color: #0dca0d;"></div>
                                <div class="d-flex position-absolute w-100 h-100 px-3 dark justify-content-between align-items-center">
                                    <h5 class="mb-0">Etape 01</h5>
                                    <div class="text-dark">
                                        <small class="fw-semibold"><div class="counter counter-inherit counter-instant"></div></small>
                                    </div>
                                </div>
                            </div>
                        </div>                                                                           
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>