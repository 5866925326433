
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class SimulatorSession {

    private config = {
        colorBorder: '',
        colorButton: '',
    }

    private simulator = {
        id: '',
        created: '',
        checkoutCancelUrl: environment.simulatorCheckoutCancelUrl,
        checkoutSuccessUrl: environment.simulatorCheckoutSuccessUrl,        
        price: '',
        step1: {
            title: 'Votre Business existe ?',
            response: '',
            check: false,
            next: ''
        },
        step2: {
            title: 'Quelle type de Business vous voulez lancer ?',
            response: '',
            check: false,
            next: ''
        },
        step3: {
            title: 'Combien souhaitez vous générer avec votre Business ?',
            response: '',
            check: false,
            next: ''
        },
        step4: {
            title: 'Génerer tu de l\'argent avec ton Business ?',
            response: '',
            check: false,
            next: ''
        },
        step5: {
            title: 'As-tu une identité de marque avec ton Business ?',
            response: '',
            check: false,
            next: ''
        },
        step6: {
            title: 'Es-tu sur le réseaux avec ton Business ?',
            response: '',
            check: false,
            next: ''
        },
        step7: {
            title: 'As-tu un site internet pour ton Business ?',
            response: '',
            check: false,
            next: ''
        },
        step8: {
            title: 'Combien de temps consacres-tu pour ce Business ?',
            response: '',
            check: false,
            next: ''
        },
        stepEmail: {
            title: 'Votre adresse email',
            response: '',
            email: '',
            check: false,
            next: ''
        }
    };


    
    constructor() {
        this.loadState();
    }

    
    loadState(): void {
        const savedState = this.getItem('simulator');
        if (savedState) {
            this.simulator = savedState;
        }
    }

    setSimulatorStepOneAnswer(response: string): void {
        this.simulator.step1.response = response;
        this.saveState();
    }

    getSimulatorStepOneAnswer(): any {
        return this.simulator.step1;
    }

    saveState(): void {
        this.setItem('simulator', this.simulator);

    }


    setItem(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    getItem(key: string): any {
        const item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }

    clearSession(): void {
        sessionStorage.clear();
    }

}


















