import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

// Déclaration de la fonction `fbq` pour le suivi Facebook Pixel
declare var fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  // URL pour obtenir l'adresse IP de l'utilisateur
  private ipUrl = 'https://api.ipify.org?format=json';

  // URL pour obtenir les informations géographiques basées sur l'IP
  private geoUrl = 'https://ipapi.co/{ip}/json/'; 

  // En-tête pour les requêtes sans authentification
  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  /**
   * Récupère l'adresse IP de l'utilisateur.
   * @returns Un Observable contenant l'adresse IP.
   */
  getIpAddress(): Observable<{ ip: string }> {
    return this.http.get<{ ip: string }>(this.ipUrl);
  }

  /**
   * Initialise le Pixel Facebook avec l'ID fourni.
   * @param pixelId L'ID du Pixel Facebook à initialiser.
   */
  init(pixelId: string) {
    if (environment.production) { // Ne fonctionne que si l'environnement est en production
      fbq('init', pixelId);
    }  
  }

  /**
   * Envoie un événement de suivi à Facebook.
   * @param eventName Le nom de l'événement à suivre.
   * @param eventData Les données supplémentaires pour l'événement (facultatif).
   */
  track(eventName: string, eventData = {}) {
    if (environment.production) { // Ne fonctionne que si l'environnement est en production
      fbq('track', eventName, eventData);
    }
  }

  /**
   * Envoie un événement personnalisé à Facebook via l'API backend.
   * @param pixelId L'ID du Pixel Facebook.
   * @param accessToken Le jeton d'accès pour l'API Facebook.
   * @param event Le nom de l'événement.
   * @param email L'email de l'utilisateur, qui sera haché avant d'être envoyé (facultatif).
   * @param cartObject Les données du panier (facultatif).
   */
  sendEventToFacebook(pixelId: string, accessToken: string, event: string, email?: string, cartObject?: any) {
    // Récupère l'adresse IP de l'utilisateur
    this.getIpAddress().subscribe(ipData => {
      const userIp = ipData.ip;
      const userAgent = navigator.userAgent; // Récupère l'agent utilisateur
      const eventSourceUrl = window.location.href; // Récupère l'URL source de l'événement
      const fbcCookie = this.getFbcCookie();  // Récupère le cookie _fbc
      const fbpCookie = this.getFbpCookie();  // Récupère le cookie _fbp

      // Récupère les données de géolocalisation basées sur l'IP
      this.getGeolocationData(userIp).subscribe(geoData => {
        const city = geoData.city || '';
        const state = geoData.region || '';
        const postalCode = geoData.postal || '';
        const country = geoData.country_code || '';

        // Crée l'objet de données pour l'événement
        const eventData: any = {
          event_name: event,
          event_time: Math.floor(Date.now() / 1000),  // Temps actuel en secondes
          user_data: {
            client_ip_address: userIp,
            client_user_agent: userAgent,
            fbc: fbcCookie,
            fbp: fbpCookie,
            ct: city,
            st: state,
            zp: postalCode,
            country: country
          },
          event_source_url: eventSourceUrl,
          action_source: "website"
        };

        // Ajoute l'email haché s'il est disponible
        if (email) {
          eventData.user_data.em = email;
        }

        // Ajoute les données du panier si disponibles
        if (cartObject) {
          eventData.custom_data = {
            content_name: cartObject.content_name,
            content_ids: cartObject.content_ids,
            content_type: cartObject.content_type,
            value: cartObject.value,
            currency: cartObject.currency,
            quantity: cartObject.quantity
          };
        } else {
          eventData.custom_data = {
            simulation_type: "business"
          };
        }

        if (environment.production) {          
          // Envoie les données à l'API backend pour être relayées à Facebook
          this.http.post(environment.apiBaseUrl + '/facebook/event', { eventData, pixelId, accessToken }).subscribe({
            next: (response: any) => {
              console.log(response); // Affiche la réponse de l'API
            },
            error: (error: any) => {
              // console.error('Error sending event:', error); // Affiche l'erreur en cas d'échec
            }
          });
        }
      });
    });
  }

  /**
   * Récupère les données de géolocalisation basées sur l'IP.
   * @param ip L'adresse IP de l'utilisateur.
   * @returns Un Observable contenant les informations géographiques.
   */
  getGeolocationData(ip: string): Observable<any> {
    const url = this.geoUrl.replace('{ip}', ip);
    return this.http.get(url);
  }

  /**
   * Récupère la valeur du cookie _fbc.
   * @returns La valeur du cookie _fbc ou null si non trouvé.
   */
  getFbcCookie(): string | null {
    let fbc = null;
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('_fbc=')) {
        fbc = cookie.substring('_fbc='.length, cookie.length);
        break;
      }
    }
    return fbc;
  }

  /**
   * Récupère la valeur du cookie _fbp.
   * @returns La valeur du cookie _fbp ou null si non trouvé.
   */
  getFbpCookie(): string | null {
    const fbpCookieName = '_fbp';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.startsWith(`${fbpCookieName}=`)) {
        return cookie.substring(fbpCookieName.length + 1); // +1 pour ignorer le "="
      }
    }
    return null;
  }

}
