import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorSession } from "../../../session/simulator.session";
import { SimulatorSessionv1 } from "../../../session/simulatorv1.session";
import { FacebookService } from '../../../services/facebook.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-bu-01-step01',
  templateUrl: './bu-01-step01.component.html',
  styleUrls: ['./bu-01-step01.component.css']
})
export class Bu01Step01Component {

  // Stocke les données de la session utilisateur
  session: any = {};

  // Variables pour gérer l'état des cartes sélectionnées
  isCardSelected01: boolean = false;
  isCardSelected02: boolean = false;
  isCardSelected03: boolean = false;

  // Tableau pour gérer l'état de survol des boutons
  isHovered: boolean[] = [false, false]; 

  // Constructeur qui injecte les services nécessaires
  constructor(private router: Router, private simulatorSession: SimulatorSession, private simulatorSessionv1: SimulatorSessionv1, private facebookService: FacebookService) {}

  // Fonction exécutée lors de l'initialisation du composant
  ngOnInit(): void {
    // Récupère la session utilisateur
    this.session = this.simulatorSessionv1.getItem(environment.products.simulatorbu01.sessionId);      

    // Si la session est nulle ou si le genre n'a pas été sélectionné, redirige vers l'étape précédente
    if (this.session === null || this.session.gender.check === false) {
      this.router.navigateByUrl('/business-1/simulateur/etape/0');         
    }

    // Initialise le pixel Facebook pour le suivi des événements
    this.facebookService.init(environment.products.simulatorbu01.pixel);
  }

  // Fonction pour gérer la navigation vers l'étape suivante
  navigateNextStep(response: string, point: any) {
    // Enregistre la réponse de l'utilisateur pour l'étape actuelle dans la session
    this.session.step1.response = response;
    this.session.step1.check = true;
    this.session.step1.point = point;

    // Met à jour la session avec les nouvelles informations
    this.simulatorSessionv1.setItem(environment.products.simulatorbu01.sessionId, this.session);

    // Met à jour l'état des cartes en fonction de la réponse sélectionnée
    if (response === '01') {
      this.isCardSelected01 = true;
      this.isCardSelected02 = false;
      this.isCardSelected03 = false;
    } else if (response === '02') {
      this.isCardSelected01 = false;
      this.isCardSelected02 = true;
      this.isCardSelected03 = false;
    } else if (response === '03') {
      this.isCardSelected01 = false;
      this.isCardSelected02 = false;
      this.isCardSelected03 = true;
    }

    // Envoie un événement à Facebook indiquant que l'étape 1 est terminée
    this.facebookService.track('Step1_Completed');
    this.facebookService.sendEventToFacebook(environment.products.simulatorbu01.pixel, environment.products.simulatorbu01.accessToken, 'Step1_Completed');
    
    // Redirige l'utilisateur vers l'étape suivante après un délai de 0.5 seconde
    setTimeout(() => {
      this.router.navigateByUrl('business-1/simulateur/etape/2');
    }, 500);
  }

  // Fonction pour gérer l'événement de survol d'un bouton
  onMouseEnter(index: number) {    
    this.isHovered[index] = true;
  }

  // Fonction pour gérer l'événement de sortie de survol d'un bouton
  onMouseLeave(index: number) {    
    this.isHovered[index] = false;
  }

}
