
	<!-- Slider
		============================================= -->
		<div id="slider" class="slider-element min-vh-0">
			<div class="container">
				<div class="row justify-content-between align-items-center align-items-xl-end">
					<div class="col-lg-6 col-xxl-5 py-5 py-lg-6 my-lg-5 text-center text-lg-start">
						<h1 class="display-4 fw-bolder ls-n-1">Build Powerful Websites in a flash.</h1>
						<p class="lead fw-normal mb-5">Transforming Your Ideas into Stunning Websites with Intuitive Tools and Limitless Possibilities.</p>
						<a routerLink="/business-1/simulateur/etape/1" class="button button-large px-lg-5 py-lg-3 rounded-pill m-0 mb-4"><i class="fa-solid fa-star"></i>Lance ton business</a>
						<!-- <a href="#" class="button button-large px-lg-5 py-lg-3 button-dark button-border button-black border-contrast-500 rounded-pill ms-1 mb-4"><i class="fa-solid fa-headset"></i>Contact Us</a> -->
					</div>
					<div class="col-lg-6 col-xxl-7">
						<img src="assets/images/hero.svg" alt="..">
					</div>
				</div>
			</div>
		</div><!-- #slider end -->

		<!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap py-0" >
                <div class="border-bottom py-4">
					<div class="container">
						<div class="row align-items-center gy-3">
							<div class="col-lg-4 d-none d-lg-block">
								<p class="fw-medium mb-0">Trusted by 70K+ Clients &mdash;</p>
							</div>

							<div class="col-lg-8">
								<div class="row g-4 align-items-center justify-content-center">
									<div class="col-md-2 col-4 flex-fill text-center op-08"><img height="46" src="assets/images/clients/cnn.svg" alt="Brands"></div>
									<div class="col-md-2 col-4 flex-fill text-center op-08"><img height="46" src="assets/images/clients/github.svg" alt="Brands"></div>
									<div class="col-md-2 col-4 flex-fill text-center op-08"><img height="46" src="assets/images/clients/google.svg" alt="Brands"></div>
									<div class="col-md-2 col-4 flex-fill text-center op-08"><img height="46" src="assets/images/clients/paypal.svg" alt="Brands"></div>
									<div class="col-md-2 col-4 flex-fill text-center op-08"><img height="46" src="assets/images/clients/vimeo.svg" alt="Brands"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div id="section-examples" class="section page-section dark bg-black" >
                    <div class="container z-3">
                        <div class="position-md-absolute start-0 top-0 w-100 h-100">
                            <div class="row">
                                <div class="col-xl-7 col-lg-10">
                                    <span class="text-uppercase small ls-4 text-contrast-500 mb-3 d-inline-block" style="--cnvs-font-size-small: .75rem">Know More About Us</span>
                                    <h3 class="fs-1 mb-4">Canvas is a Powerful &amp; Raw Multipurpose HTML5 Template.</h3>

                                    <ul class="list-inline">
                                        <li class="list-inline-item small me-4"><i class="fa-solid fa-check me-1"></i> Powered by SemiColonWeb</li>
                                        <li class="list-inline-item small me-4"><i class="fa-solid fa-check me-1"></i> Ultimate Design</li>
                                        <li class="list-inline-item small"><i class="fa-solid fa-check me-1"></i> Multi-Purpose Template</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img class="d-none d-md-block z-2 position-relative" src="assets/images/cards-bg.png" alt="...">
                </div>
            </div>
        </section>



<app-simulator-footer></app-simulator-footer>