import { Component } from '@angular/core';

@Component({
  selector: 'app-simulator-header',
  templateUrl: './simulator-header.component.html',
  styleUrls: ['./simulator-header.component.css']
})
export class SimulatorHeaderComponent {

}
