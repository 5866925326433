import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorSessionv1 } from "../../../session/simulatorv1.session";
import { FacebookService } from '../../../services/facebook.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-bu-01-step00',
  templateUrl: './bu-01-step00.component.html',
  styleUrls: ['./bu-01-step00.component.css']
})
export class Bu01Step00Component {

  // Session de simulation, pour stocker et récupérer les données de l'utilisateur
  session: any = {};

  // États pour gérer la sélection et le survol des cartes
  isCardSelected01: boolean = false;
  isCardSelected02: boolean = false;
  isHovered: boolean[] = [false, false]; // Tableau pour stocker l'état de survol de chaque bouton

  // Constructeur qui injecte les services nécessaires
  constructor(private router: Router, private simulatorSessionv1: SimulatorSessionv1, private facebookService: FacebookService) { }

  // Fonction pour gérer l'événement de survol d'une carte
  onMouseEnter(index: number) {
    this.isHovered[index] = true;
  }

  // Fonction pour gérer l'événement de sortie de survol d'une carte
  onMouseLeave(index: number) {
    this.isHovered[index] = false;
  }

  // Fonction exécutée lors de l'initialisation du composant
  ngOnInit(): void {
    // Initialisation du pixel Facebook
    this.facebookService.init(environment.products.simulatorbu01.pixel);

    // Envoi d'un événement de début de simulation à Facebook via l'API backend
    this.facebookService.sendEventToFacebook(environment.products.simulatorbu01.pixel, environment.products.simulatorbu01.accessToken, 'StartSimulation');

    // Initialisation de la session de simulation
    this.simulatorSessionv1.initializeSimulator(environment.products.simulatorbu01.sessionId);
    this.session = this.simulatorSessionv1.getItem(environment.products.simulatorbu01.sessionId);

    // Si la session est nulle, on la charge et on la sauvegarde
    if (this.session == null) {
      this.simulatorSessionv1.loadState(environment.products.simulatorbu01.sessionId);
      this.simulatorSessionv1.saveState(environment.products.simulatorbu01.sessionId);
    }

    // Récupération de la session mise à jour
    this.session = this.simulatorSessionv1.getItem(environment.products.simulatorbu01.sessionId);
  }

  // Fonction pour naviguer à l'étape suivante et gérer la sélection du genre
  navigateNextStep(response: string) {
    this.session.gender.response = response;
    this.session.gender.check = true;
    this.simulatorSessionv1.setItem(environment.products.simulatorbu01.sessionId, this.session);

    // Mise à jour de l'état des cartes en fonction du genre sélectionné
    if (response === 'men') {
      this.isCardSelected01 = true;
      this.isCardSelected02 = false;
    } else if (response === 'women') {
      this.isCardSelected01 = false;
      this.isCardSelected02 = true;
    }

    // Envoi d'un événement de complétion de l'étape à Facebook
    this.facebookService.track('Step0_Completed');
    this.facebookService.sendEventToFacebook(environment.products.simulatorbu01.pixel, environment.products.simulatorbu01.accessToken, 'Step0_Completed');
    
    // Navigation vers l'étape suivante après un délai de 0.5 seconde
    setTimeout(() => {
      this.router.navigateByUrl('business-1/simulateur/etape/1');
    }, 500); // 500 millisecondes = 0.5 seconde
  }

  // Fonction pour récupérer la couleur du bouton en fonction du genre sélectionné
  getButtonColor(): string {
    if (this.session.gender.response === 'men') {
      return this.session.theme.menColor;
    } else if (this.session.gender.response === 'women') {
      return this.session.theme.womenColor;
    }
    return this.session.theme.defaultColor;
  }

  // Fonction pour sélectionner le genre et mettre à jour la session
  selectGender(gender: string): void {
    this.session.gender.response = gender;
    this.simulatorSessionv1.setItem(environment.products.simulatorbu01.sessionId, this.session);

    // Mise à jour de l'état des cartes en fonction du genre sélectionné
    if (gender === 'men') {
      this.isCardSelected01 = true;
      this.isCardSelected02 = false;
    } else if (gender === 'women') {
      this.isCardSelected01 = false;
      this.isCardSelected02 = true;
    }
  }
}
