<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-12 col-lg-12">
                        <a *ngIf="session.gender.response === 'men'"
                            [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':  session.theme.men.textColor}"
                            routerLink="/business-1/simulateur/etape/0" class="button  button-rounded fw-bolder">Ton
                            genre ?</a>
                        <a *ngIf="session.gender.response === 'women'"
                            [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':  session.theme.women.textColor}"
                            routerLink="/business-1/simulateur/etape/0" class="button  button-rounded fw-bolder">Ton
                            genre ?</a>

                        <a *ngIf="session.gender.check === false" style="background-color: #F0F1F2; color: rgb(0, 0, 0);"
                            routerLink="/business-1/simulateur/etape/0" class="button  button-rounded fw-bolder">Votre
                            genre ?</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="content" class="pt-5">
    <div class="content-wrap py-10">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative">Choisis ton genre</h3>
                </div>
                <div class="row align-items-stretch g-4 center mb-5">                  
                    <div class="col-md-6 text-center ">
                        <div class="card bg-secondary bg-opacity-10 border-0 border-radius-10">
                            <div class="card-body p-4 pointer border-radius-10" [ngStyle]="{
                                'background-color': isCardSelected01 ? session.theme.men.backgroundColor  : '#F0F1F2',
                                'border': session.gender.response === 'men' ? session.theme.men.borderColor  : 'none'
                                }" (click)="navigateNextStep('men')">
                                <h4 *ngIf="isCardSelected01 === false" class="mb-3 fw-bolder tile-question ">Un homme </h4>
                                <h4 *ngIf="isCardSelected01 === true"  class="mb-3 fw-bolder tile-question text-white">Un homme </h4>
                                <img src="assets/images/etp-men.png" width="150px">
                                <br>
                                <br>
                                <a class="button button-large button-circle fw-bolder"
                                    [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':  session.theme.men.textColor}"
                                    [ngClass]="{'hovered': isHovered[0]}" (mouseenter)="onMouseEnter(0)"
                                    (mouseleave)="onMouseLeave(0)">
                                    <i *ngIf="session.gender.response === 'men'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6 text-center ">
                        <div class="card bg-secondary bg-opacity-10 border-0 border-radius-10">
                            <div class="card-body p-4 pointer border-radius-10" [ngStyle]="{
                                'background-color': isCardSelected02 ? session.theme.women.backgroundColor  : '#F0F1F2',
                                'border': session.gender.response === 'women' ? session.theme.women.borderColor  : 'none'
                                }" (click)="navigateNextStep('women')">
                                <h4 *ngIf="isCardSelected02 === false" class="mb-3 fw-bolder tile-question ">Une femme </h4>
                                <h4 *ngIf="isCardSelected02 === true" class="mb-3 fw-bolder tile-question text-white">Une femme </h4>
                                <img src="assets/images/etp-women.png" width="150px">
                                <br>
                                <br>
                                <a class="button button-large button-circle fw-bolder"
                                    [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':  session.theme.women.textColor}"
                                    [ngClass]="{'hovered': isHovered[1]}" (mouseenter)="onMouseEnter(1)"
                                    (mouseleave)="onMouseLeave(1)">
                                    <i *ngIf="session.gender.response === 'women'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>





                    <!-- <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer"
                                [class.selected-border]="session.gender.response === 'women'"
                                [ngStyle]="{'background-color': isCardSelected02 ? '#ffd600' : '#F0F1F2'}"
                                (click)="navigateNextStep('women')">
                                <h4 class="mb-3 fw-bolder tile-question">Une femme</h4>
                                <img src="assets/images/etp-women.png" width="250px">
                                <br>
                                <br>
                                <a class="button button-large button-circle fw-bolder"
                                    [ngStyle]="{'background-color': isCardSelected02 ? '#ffd600' : '#FFD600; color:black;'}">
                                    <i *ngIf="session.gender.response === 'women'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                        aria-valuemax="100" style="width: 0%;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>