import { Component } from '@angular/core';
import { EbookService } from '../../services/ebook.service';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import { FacebookService } from '../../services/facebook.service';

@Component({
  selector: 'app-product-ebook02',
  templateUrl: './product-ebook02.component.html',
  styleUrls: ['./product-ebook02.component.css']
})
export class ProductEbook02Component {


  id: any = 'ebook02';
  ref: any = 'gagner-de-l-argent-sans-te-montrer-guide';
  price: any = 'price_1P63i2BTvHG2hcfS5Jb6GeKl';
  pixel: any = '440518478470187';

  error: any;
  checkoutButton : any = true;
  checkoutButtonLoading : any = false;

  constructor(private router: Router, private ebookService: EbookService,private facebookService: FacebookService) { }

  ngOnInit(): void {
    this.checkoutButton = true;
    this.checkoutButtonLoading = false;

    if (environment.production) {
      this.facebookService.init(this.pixel);
      this.facebookService.track('PageView');
      console.log('here PageView');
    }

  }


  checkout() {
    this.checkoutButton = false;
    this.checkoutButtonLoading = true;


    if (environment.production) {
      this.facebookService.init(this.pixel);
      this.facebookService.track('AddToCart');
      console.log('here PageView');
    }

    this.checkoutButton
    let data = {
      id:  environment.products.ebooks.ebook02.id,
      ref: environment.products.ebooks.ebook02.ref,
      price: environment.products.ebooks.ebook02.price,
      pixel: this.pixel,
      checkoutCancelUrl: environment.ebookCheckoutCancelUrl,
      checkoutSuccessUrl: environment.ebookCheckoutSuccessUrl ,
      stripe: environment.stripe    
    }

    this.ebookService.checkout(data).subscribe({
      next: (response: any) => {
        /* success callback */
        window.location.href = response.session.url;;
      },
      error: (error: any) => {
        /* error callback */
        this.error = true;
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        setTimeout(() => {
          this.error = null;
        }, 3500); // 500 millisecondes = 0.5 seconde       
      }
    });
  }

}
