import { Component } from '@angular/core';

@Component({
  selector: 'app-simulator-footer',
  templateUrl: './simulator-footer.component.html',
  styleUrls: ['./simulator-footer.component.css']
})
export class SimulatorFooterComponent {


  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngAfterViewInit() {    
    this.loadExternalScript('assets/js/functions.js');
  }

}
