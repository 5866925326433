import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorSessionv1 } from "../../../session/simulatorv1.session";
import { FacebookService } from '../../../services/facebook.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-bu-01-step02',
  templateUrl: './bu-01-step02.component.html',
  styleUrls: ['./bu-01-step02.component.css']
})
export class Bu01Step02Component {

  // Objet pour stocker les données de la session actuelle de l'utilisateur
  session: any = {};

  // Variables booléennes pour déterminer quelle carte est sélectionnée
  isCardSelected01: boolean = false;
  isCardSelected02: boolean = false;
  isCardSelected03: boolean = false;
  isCardSelected04: boolean = false;

  // Tableau pour suivre l'état de survol des éléments interactifs
  isHovered: boolean[] = [false, false]; 

  /**
   * Constructeur injectant les services nécessaires :
   * - Router : pour la navigation entre les routes
   * - SimulatorSessionv1 : pour gérer les données de session du simulateur
   * - FacebookService : pour gérer le suivi et les événements Facebook Pixel
   */
  constructor(
    private router: Router,
    private simulatorSessionv1: SimulatorSessionv1,
    private facebookService: FacebookService
  ) { }

  /**
   * Méthode appelée lors de l'initialisation du composant.
   * - Récupère la session en cours.
   * - Vérifie si les étapes précédentes ont été complétées.
   * - Initialise le Facebook Pixel.
   */
  ngOnInit(): void {
    // Récupération de la session utilisateur depuis le service
    this.session = this.simulatorSessionv1.getItem(environment.products.simulatorbu01.sessionId);      

    // Vérification que la session existe et que l'étape précédente a été complétée
    if (this.session === null || this.session.step1.check === false) {
      // Redirection vers l'étape précédente si la condition n'est pas remplie
      this.router.navigateByUrl('/business-1/simulateur/etape/1');
    }

    // Initialisation du Facebook Pixel avec l'ID spécifié dans les variables d'environnement
    this.facebookService.init(environment.products.simulatorbu01.pixel);
  }

  /**
   * Méthode pour gérer la sélection de réponse et la navigation vers l'étape suivante.
   * @param response - Identifiant de la réponse sélectionnée.
   * @param point - Points associés à la réponse sélectionnée.
   */
  navigateNextStep(response: string, point: any): void {
    // Mise à jour de la session avec la réponse et les points sélectionnés
    this.session.step2.response = response;
    this.session.step2.check = true;
    this.session.step2.point = point;

    // Sauvegarde de la session mise à jour dans le service
    this.simulatorSessionv1.setItem(environment.products.simulatorbu01.sessionId, this.session);

    // Réinitialisation de l'état de sélection des cartes
    this.isCardSelected01 = false;
    this.isCardSelected02 = false;
    this.isCardSelected03 = false;
    this.isCardSelected04 = false;

    // Mise à jour de l'état de la carte sélectionnée en fonction de la réponse
    switch(response) {
      case '01':
        this.isCardSelected01 = true;
        break;
      case '02':
        this.isCardSelected02 = true;
        break;
      case '03':
        this.isCardSelected03 = true;
        break;
      case '04':
        this.isCardSelected04 = true;
        break;
      default:
        break;
    }

    // Envoi d'un événement de complétion de l'étape au Facebook Pixel
    this.facebookService.track('Step2_Completed');
    this.facebookService.sendEventToFacebook(
      environment.products.simulatorbu01.pixel,
      environment.products.simulatorbu01.accessToken,
      'Step2_Completed'
    );

    // Navigation vers l'étape suivante après un délai de 500 millisecondes
    setTimeout(() => {
      this.router.navigateByUrl('business-1/simulateur/etape/3');
    }, 500);
  }

  /**
   * Méthode appelée lorsque la souris entre dans la zone d'un élément interactif.
   * @param index - Index de l'élément survolé.
   */
  onMouseEnter(index: number): void {
    this.isHovered[index] = true;
  }

  /**
   * Méthode appelée lorsque la souris quitte la zone d'un élément interactif.
   * @param index - Index de l'élément dont le survol se termine.
   */
  onMouseLeave(index: number): void {
    this.isHovered[index] = false;
  }

}
