
<!-- Footer
============================================= -->
<footer id="footer" class="bg-white border-0">
    <!-- Copyrights
============================================= -->
    <div id="copyrights" class="bg-transparent border-top op-08">
        <div class="container text-center">
            <img src="assets/images/logo.jpg" width="280px"><br>
            Copyrights &copy; 2024 All Rights Reserved by cestnow
            <div class="copyright-links">
                <a routerLink="/mentionslegales" target="_blank">Mention légales</a> / <a routerLink="/mentionslegales" target="_blank">Conditions générales de vente</a></div>

        </div>
    </div>
</footer><!-- #footer end -->