import { Component } from '@angular/core';

@Component({
  selector: 'app-products-simulator',
  templateUrl: './products-simulator.component.html',
  styleUrls: ['./products-simulator.component.css']
})
export class ProductsSimulatorComponent {

}
