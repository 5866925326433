<div id="top-bar" class="center dark pt-3" style="background-color: #FFD600">
    <p class="mb-3 text-white" style="font-size: 18px;">
        <b>
            <span>Nouveau produit « le guide business ».</span>
        </b>
        <!-- &nbsp; -->
        <!-- &nbsp; -->
        <!-- &nbsp; -->
        <a routerLink="business-1/le-guide-ultime" class="ms-2 font-primary fw-bold text-white">
            <u><b>Je regarde ici</b></u> ›
        </a>
    </p>
</div>


<!-- 
<div id="slider" class="slider-element min-vh-0">
    <div class="container">
        <div class="row justify-content-between align-items-center align-items-xl-end">
            <div class="col-lg-6 col-xxl-5 py-5 py-lg-6 my-lg-5 text-center text-lg-start">
                <h1 class="display-4 fw-bolder ls-n-1">C'est <span style="background-color: #7550B2; color: rgb(255, 255, 255);">Now</span></h1>
                <p class="lead fw-normal mb-5">C'est Now est une plateforme qui offre une vaste gamme de contenus sur divers thèmes comme le business et le bien-être. Explorez des ressources enrichissantes pour améliorer votre entreprise et votre qualité de vie. Rejoignez-nous pour découvrir des sujets variés et inspirants adaptés à vos intérêts.</p>                
                <a routerLink="/business-1/simulateur/etape/0" class="button button-large button-circle fw-bolder pointer"><i class="fa-solid fa-business-time"></i> Lance ton business</a>
            </div>
            <div class="col-lg-6 col-xxl-7">
                <img src="assets/images/hero.svg" alt="...">
            </div>
        </div>
    </div>
</div> -->

<section id="content">
    <div class="content-wrap py-0">
        <div id="section-services" class="page-section py-5 py-lg-0">
            <div class="container">
                <div class="clear"></div>
                <div class="row justify-content-center mt-lg-6 mt-5">
                    <div class="col-lg-8 text-center">
                        <h1 class="display-4 fw-bolder ls-n-1">C'est <span
                                style="background-color: #7550B2; color: rgb(255, 255, 255);">Now !</span></h1>
                        <p class="fw-bold mb-5">C'est Now est une plateforme qui offre une vaste gamme de contenus sur
                            divers thèmes comme le business et le bien-être. Explorez des ressources enrichissantes pour
                            améliorer votre entreprise et votre qualité de vie. Rejoignez-nous pour découvrir des sujets
                            variés et inspirants adaptés à vos intérêts.</p>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-lg-12">
                        <!-- Section Featured Boxes
                        ============================================= -->
                        <div class="row align-items-stretch g-4">
                            <div class="col-lg-4">
                                <div class="entry d-flex flex-column h-100 mb-0 bg-contrast-0 p-4 rounded-xxl"
                                    style="--cnvs-post-item-margin:0;">
                                    <div class="entry-image mb-3">
                                        <a routerLink="ebook/comment-elever-l-amour-propre-et-guérir-l-esprit-le-corps-et-l-ame">
                                            <img src="assets/images/ebooks/ebook01/1.jpg" alt="Image 3"
                                                class="rounded-6">
                                        </a>
                                    </div>
                                    <div class="entry-desc mb-auto">
                                        <span class="badge rounded-pill text-bg-light px-3 py-2 mb-2 fw-bolder"
                                            style="background-color : #c7ac97 !important; color: white !important;">Bien-être</span>                                        
                                        <div class="entry-title">
                                            <h3 class="mb-0 fs-5 font-body fw-medium"><a class="text-black" routerLink="ebook/comment-elever-l-amour-propre-et-guérir-l-esprit-le-corps-et-l-ame">Comment élever l'amour-propre et guérir l'esprit, le corps et l'âme</a></h3>
                                        </div>
                                    </div>
                                    <div class="d-flex text-contrast-600 border-top mt-3 pt-3 small">
                                        <span>Note :</span>
                                        <div class="testimonials-rating">
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="entry d-flex flex-column h-100 mb-0 bg-contrast-0 p-4 rounded-xxl"
                                    style="--cnvs-post-item-margin:0;">
                                    <div class="entry-image mb-3">
                                        <a routerLink="/ebook/guide-faceless-reels">
                                            <img src="assets/images/ebooks/ebook03/1.jpg" alt="Image 3"
                                                class="rounded-6">
                                        </a>
                                    </div>
                                    <div class="entry-desc mb-auto">
                                        <span class="badge rounded-pill text-bg-light px-3 py-2 mb-2 fw-bolder"
                                            style="background-color : #9A42A4 !important; color: white !important;">Réseaux sociaux</span>                                        
                                        <div class="entry-title">
                                            <h3 class="mb-0 fs-5 font-body fw-medium"><a class="text-black" routerLink="/ebook/guide-faceless-reels">Plonge dans l'univers des Réels Instagram sans montrer ton visage</a></h3>
                                        </div>
                                    </div>
                                    <div class="d-flex text-contrast-600 border-top mt-3 pt-3 small">
                                        <span>Note :</span>
                                        <div class="testimonials-rating">
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="entry d-flex flex-column h-100 mb-0 bg-contrast-0 p-4 rounded-xxl"
                                    style="--cnvs-post-item-margin:0;">
                                    <div class="entry-image mb-3">
                                        <a routerLink="/business-1/le-guide-ultime">
                                            <img src="assets/images/simulator/le-guide-ultime.jpg" alt="Image 3"
                                                class="rounded-6">
                                        </a>
                                    </div>
                                    <div class="entry-desc mb-auto">
                                        <span class="badge rounded-pill text-bg-light px-3 py-2 mb-2 fw-bolder"
                                            style="background-color : #ffd600 !important; color: white !important;">Business</span>                                        
                                        <div class="entry-title">
                                            <h3 class="mb-0 fs-5 font-body fw-medium"><a class="text-black" routerLink="/business-1/le-guide-ultime">Le guide ultime pour créer ou optimiser ton Business</a></h3>
                                        </div>
                                    </div>
                                    <div class="d-flex text-contrast-600 border-top mt-3 pt-3 small">                                        
                                        <span>Note :</span>
                                        <div class="testimonials-rating">
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>                      
                            <div class="col-lg-4">
                                <div class="entry d-flex flex-column h-100 mb-0 bg-contrast-0 p-4 rounded-xxl"
                                    style="--cnvs-post-item-margin:0;">
                                    <div class="entry-image mb-3">
                                        <a routerLink="/business-1/le-guide-ultime">
                                            <img src="assets/images/ebooks/ebook04/1.jpg" alt="Image 3"
                                                class="rounded-6">
                                        </a>
                                    </div>
                                    <div class="entry-desc mb-auto">
                                        <span class="badge rounded-pill text-bg-light px-3 py-2 mb-2 fw-bolder"
                                            style="background-color : #ff9100 !important; color: white !important;">Education</span>                                        
                                        <div class="entry-title">
                                            <h3 class="mb-0 fs-5 font-body fw-medium"><a class="text-black" routerLink="/business-1/le-guide-ultime">Créer un vraie relation avec ton chien</a></h3>
                                        </div>
                                    </div>
                                    <div class="d-flex text-contrast-600 border-top mt-3 pt-3 small">                                        
                                        <span>Note :</span>
                                        <div class="testimonials-rating">
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>                      
                            <div class="col-lg-4">
                                <div class="entry d-flex flex-column h-100 mb-0 bg-contrast-0 p-4 rounded-xxl"
                                    style="--cnvs-post-item-margin:0;">
                                    <div class="entry-image mb-3">
                                        <a routerLink="/business-1/le-guide-ultime">
                                            <img src="assets/images/ebooks/ebook05/1.jpg" alt="Image 3"
                                                class="rounded-6">
                                        </a>
                                    </div>
                                    <div class="entry-desc mb-auto">
                                        <span class="badge rounded-pill text-bg-light px-3 py-2 mb-2 fw-bolder"
                                            style="background-color : #ffd600 !important; color: white !important;">Business</span>                                        
                                        <div class="entry-title">
                                            <h3 class="mb-0 fs-5 font-body fw-medium"><a class="text-black" routerLink="/business-1/le-guide-ultime">Construire une boutique qui tourne 24/24</a></h3>
                                        </div>
                                    </div>
                                    <div class="d-flex text-contrast-600 border-top mt-3 pt-3 small">                                        
                                        <span>Note :</span>
                                        <div class="testimonials-rating">
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                            <i class="bi-star-fill color-star"></i>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>                      
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="clear"></div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>