<!-- <section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">                    
                    <div class="col-lg-12">
                        <div class="row g-4 align-items-center justify-content-center">
                            <div id="tab-reservation" class="nav nav-pills flex-column flex-lg-row" role="tablist">
                                <a class="nav-link active ms-0 fw-bolder" routerLink="/simulator/2" ><i class="bi-arrow-left-circle-fill"></i></a>                                                                                                        
                                <a class="nav-link active ms-0 mt-2 mt-lg-0 ms-lg-3  fw-bolder" routerLink="/simulator/3">3. Objectif financier</a>                            
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section id="content" class="pt-5">
    <div class="content-wrap py-10">
        <div class=" py-4">
            <div class="container">              
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative">On génére votre  <span class="gradient-text gradient-color">rapport...</span></h3>                                      
                </div>
                <div class="row align-items-stretch g-4 text-center mb-5">                   
                
                    <div class="col-md-12 center-both">                                                   
                        <img src="assets/images/load.gif" width="550px">
                    </div> 
                </div>                                                   
            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>