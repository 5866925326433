import { Component } from '@angular/core';

@Component({
  selector: 'app-cestnow-products',
  templateUrl: './cestnow-products.component.html',
  styleUrls: ['./cestnow-products.component.css']
})
export class CestnowProductsComponent {

}
