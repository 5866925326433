import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorSession } from "../../session/simulator.session";

@Component({
  selector: 'app-step04',
  templateUrl: './step04.component.html',
  styleUrls: ['./step04.component.css']
})
export class Step04Component {

  session : any = {};
  isCardSelected01: boolean = false;
  isCardSelected02: boolean = false;

  constructor(private router: Router,private simulatorSession: SimulatorSession) { }

  ngOnInit(): void {   
    // Get session 
    this.session = this.simulatorSession.getItem('simulator');    
    if(this.session === null || this.session.step1.check === false) {
      this.router.navigateByUrl('simulator/1');         
    }     
  }

  navigateStepFive(response:string) {
   
    this.session.step4.response = response;
    this.session.step4.check = true;
    this.simulatorSession.setItem('simulator', this.session);

    if (response === '1') {
      this.isCardSelected01 = true;
      this.isCardSelected02 = false;
    } else if (response === '2') {
      this.isCardSelected01 = false;
      this.isCardSelected02 = true;
    }
   
    setTimeout(() => {
      this.router.navigateByUrl('simulator/5');
    }, 500); // 500 millisecondes = 0.5 seconde    
  }

}
