import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorSession } from "../../session/simulator.session";


@Component({
  selector: 'app-step01',
  templateUrl: './step01.component.html',
  styleUrls: ['./step01.component.css']
})
export class Step01Component {

  session: any = {};
  isCardSelected01: boolean = false;
  isCardSelected02: boolean = false;

  constructor(private router: Router, private simulatorSession: SimulatorSession) {}

  ngOnInit(): void {
    // Get session 
    this.session = this.simulatorSession.getItem('simulator');
    if (this.session == null) {
      this.simulatorSession.loadState();
      this.simulatorSession.saveState();
    }
    this.session = this.simulatorSession.getItem('simulator');   

    console.log(this.session);

  }




  navigateStepTwo(cardId: string) {

    this.session.step1.response = cardId;
    this.session.step1.check = true;
    this.simulatorSession.setItem('simulator', this.session);
   
    // if (cardId === '1') {
    //   this.isCardSelected01 = true;
    //   this.isCardSelected02 = false;
    // } else if (cardId === '2') {
    //   this.isCardSelected01 = false;
    //   this.isCardSelected02 = true;
    // }

    setTimeout(() => {
      this.router.navigateByUrl('simulator/2');
    }, 500); // 500 millisecondes = 0.5 seconde

  }
}
