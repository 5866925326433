import { Component } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { jwtDecode } from "jwt-decode";


@Component({
  selector: 'app-cancel-sale-ebook',
  templateUrl: './cancel-sale-ebook.component.html',
  styleUrls: ['./cancel-sale-ebook.component.css']
})
export class CancelSaleEbookComponent {

  constructor(private router: Router,private route: ActivatedRoute) { }


  ngOnInit() {
    let id :any = this.route.snapshot.paramMap.get('id');      
    const decodedToken: any = jwtDecode(id);    
    this.router.navigate(['/ebook/'+decodedToken.ref]);
  }
}
