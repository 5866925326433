import { Component } from '@angular/core';
import { EbookService } from '../../services/ebook.service';
import { FacebookService } from '../../services/facebook.service';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';

import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-product-ebook03',
  templateUrl: './product-ebook03.component.html',
  styleUrls: ['./product-ebook03.component.css']
})
export class ProductEbook03Component {

  // Couleur du thème pour le composant, initialisée à #9A42A4
  themeColor: any = '#9A42A4';

  // Variable de gestion d'erreur lors du processus de paiement
  error: any;

  // État d'activation/désactivation du bouton de paiement
  checkoutButton: any = true;

  // Indicateur de chargement pour le bouton de paiement
  checkoutButtonLoading: any = false;

  // Temps initial pour le compte à rebours (15 minutes en secondes)
  private initialTime = 15 * 60; // 15 minutes in seconds

  // Temps restant pour le compte à rebours
  timeLeft: number = 0;

  // Subscription de l'observable utilisée pour le compte à rebours
  subscription: Subscription | null = null;

  // Heure de départ pour le compte à rebours
  startTime: number | null = null;

  // Indicateur pour afficher ou masquer le compte à rebours
  showCountdown: boolean = true;

  // Prix actuel du produit
  currentPrice: number = 0;

  // Prix initial du produit
  price: number = 97;

  // Prix promotionnel 1 après expiration du compte à rebours
  pricePromo01: number = 27;

  // Prix promotionnel 2 pendant le compte à rebours
  pricePromo02: number = 17.97;

  constructor(
    private router: Router, 
    private ebookService: EbookService, 
    private facebookService: FacebookService
  ) {}

  ngOnInit(): void {
    // Chargement des scripts externes Wistia
    this.loadExternalScript('https://fast.wistia.com/embed/medias/por6vnw3c6.jsonp');
    this.loadExternalScript('https://fast.wistia.com/assets/external/E-v1.js');

    // Initialisation des états du bouton de paiement
    this.checkoutButton = true;
    this.checkoutButtonLoading = false;

    // Chargement du compte à rebours
    this.loadCountdown();
    
    // Initialisation et suivi des événements Facebook
    this.facebookService.init(environment.products.ebooks.ebook03.pixel);
    this.facebookService.track('PageView');
    this.facebookService.sendEventToFacebook(environment.products.ebooks.ebook03.pixel, environment.products.ebooks.ebook03.accessToken, 'PageView');
  }

  // Fonction pour charger un script externe
  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  // Fonction pour gérer le processus de paiement
  async checkout() {
    // Assignation du prix actuel à l'environnement du produit
    environment.products.ebooks.ebook03.price = this.currentPrice;
    this.checkoutButton = false;
    this.checkoutButtonLoading = true;

    let data = {
      id: environment.products.ebooks.ebook03.id,
      ref: environment.products.ebooks.ebook03.ref,
      price: environment.products.ebooks.ebook03.price,
      product: environment.products.ebooks.ebook03,
      pixel: environment.products.ebooks.ebook03.pixel,
      accessToken: environment.products.ebooks.ebook03.accessToken,
      themeColor: this.themeColor,
      checkoutCancelUrl: environment.ebookCheckoutCancelUrl,
      checkoutSuccessUrl: environment.ebookCheckoutSuccessUrl,
      stripe: environment.stripe
    }

    // Suivi des événements Facebook pour 'AddToCart'
    this.facebookService.init(environment.products.ebooks.ebook03.pixel);
    this.facebookService.track('AddToCart', {
      content_name: environment.products.ebooks.ebook03.ref,
      content_ids: environment.products.ebooks.ebook03.id,
      content_type: 'product',
      value: this.currentPrice,
      currency: environment.products.ebooks.ebook03.devise,
      quantity: 1
    });

    let cartObject = {
      content_name: environment.products.ebooks.ebook03.ref,
      content_ids: environment.products.ebooks.ebook03.id,
      content_type: 'product',
      value: this.currentPrice,
      currency: environment.products.ebooks.ebook03.devise,
      quantity: 1
    }

    this.facebookService.sendEventToFacebook(
      environment.products.ebooks.ebook03.pixel, 
      environment.products.ebooks.ebook03.accessToken, 
      'AddToCart', 
      '', 
      cartObject
    );

    // Appel au service de checkout pour redirection vers Stripe
    this.ebookService.checkout(data).subscribe({
      next: (response: any) => {
        /* success callback */
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        window.location.href = response.session.url;
      },
      error: (error: any) => {
        /* error callback */
        this.error = true;
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        setTimeout(() => {
          this.error = null;
        }, 3500); // 500 millisecondes = 0.5 seconde       
      }
    });
  }

  // Fonction pour faire défiler la page vers un élément spécifique
  scrollToElement(elementId: string): void {
    const element = document.querySelector(`#${elementId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  // Fonction pour charger le compte à rebours depuis le cache local
  loadCountdown() {
    const savedTime = localStorage.getItem('countdownStartTimeEbook03');
    // console.log(savedTime);
    if (savedTime) {
      const elapsed = Math.floor((Date.now() - parseInt(savedTime)) / 1000);
      this.timeLeft = this.initialTime - elapsed;
      if (this.timeLeft < 0) {
        this.timeLeft = 0; // Prevent negative countdown
      }
    } else {
      this.timeLeft = this.initialTime;
      localStorage.setItem('countdownStartTimeEbook03', Date.now().toString());
    }
    this.startCountdown();
  }

  // Fonction pour démarrer le compte à rebours
  startCountdown() {
    // console.log(`Starting countdown with timeLeft: ${this.timeLeft}`);
    if (this.timeLeft <= 0) {
      this.currentPrice = this.pricePromo01;
      // console.log('No countdown needed, time already elapsed');
      this.showCountdown = false;
      this.cleanupSubscription();
      return; // Arrête le compte à rebours immédiatement si le temps initial est déjà écoulé.
    }

    const timer$ = interval(1000).pipe(
      takeWhile(() => this.timeLeft > 0, true) // inclut la condition pour émettre également lorsque timeLeft atteint 0.
    );

    this.subscription = timer$.subscribe({
      next: () => {
        this.currentPrice = this.pricePromo02;
        this.timeLeft -= 1;
        // console.log(`Counting down: ${this.formatTime(this.timeLeft)}`);
        // console.log(this.formatTime(this.timeLeft));  // Mettre à jour l'affichage à chaque seconde
      },
      complete: () => {
        // console.log('Countdown completed');
        this.currentPrice = this.pricePromo01;
        this.showCountdown = false;
        this.cleanupSubscription();
      }
    });
  }

  // Nettoyage de la subscription pour éviter les fuites de mémoire
  cleanupSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  // Fonction pour formater le temps en minutes et secondes
  formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;
    return `${this.padZero(minutes)} MIN ${this.padZero(remainingSeconds)} SEC`;
  }

  // Fonction pour ajouter un zéro devant un nombre si nécessaire
  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;  // Assurez-vous que le formatage ajoute un zéro correctement
  }

  // Fonction pour nettoyer les ressources lors de la destruction du composant
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // Fonction pour réinitialiser le cache du compte à rebours
  resetCountdownCache() {
    this.showCountdown = true;
    localStorage.removeItem('countdownStartTimeEbook03');  // Supprime uniquement le début du compteur
    this.timeLeft = this.initialTime;               // Réinitialise timeLeft à sa valeur initiale
    localStorage.setItem('countdownStartTimeEbook03', Date.now().toString());  // Réinitialise le temps de départ
    this.startCountdown();                         // Redémarre le compteur
  }

}
