<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">                    
                    <div class="col-lg-12">
                        <div class="row g-4 align-items-center justify-content-center">
                            <div id="tab-reservation" class="nav nav-pills flex-column flex-lg-row" role="tablist">
                                <a class="nav-link active ms-0 fw-bolder" routerLink="/simulator/2" ><i class="bi-arrow-left-circle-fill"></i></a>                                                                                                        
                                <a class="nav-link active ms-0 mt-2 mt-lg-0 ms-lg-3  fw-bolder" routerLink="/simulator/3">3. Objectif financier</a>                            
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="content" class="pt-5">
    <div class="content-wrap py-10">
        <div class="border-bottom py-4">
            <div class="container">              
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative">3 / Combien souhaitez vous générer avec votre <span class="gradient-text gradient-color">Business</span> ?</h3>                  
                    <p class="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,</p>
                    <!-- <a href="#" class="button button-border button-large rounded-pill m-0">Etape 8 - Type de business ?</a> -->
                </div>
                <div class="row align-items-stretch g-4 text-center mb-5">                   
                    <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [class.selected-border]="session.step3.response === '1'"
                            [ngStyle]="{'background-color': isCardSelected01 ? '#ffd600' : '#F0F1F2'}"
                            (click)="navigateStepFor('1')">
                                <h4 class="mb-3 fw-bolder tile-question">5000€ / Mois</h4>
                                <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>
                                <a class="button button-large button-circle fw-bolder"
                                    style="background-color: #FFD600; color: black;">
                                    <i *ngIf="session.step3.response === '1'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [class.selected-border]="session.step3.response === '2'"
                            [ngStyle]="{'background-color': isCardSelected02 ? '#ffd600' : '#F0F1F2'}"
                            (click)="navigateStepFor('2')">
                                <h4 class="mb-3 fw-bolder tile-question">10 000€ / Mois</h4>
                                <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>
                                <a class="button button-large button-circle fw-bolder"
                                    style="background-color: #FFD600; color: black;">
                                    <i *ngIf="session.step3.response === '2'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [class.selected-border]="session.step3.response === '3'"
                            [ngStyle]="{'background-color': isCardSelected03 ? '#ffd600' : '#F0F1F2'}"
                            (click)="navigateStepFor('3')">
                                <h4 class="mb-3 fw-bolder tile-question">20 000€ / Mois</h4>
                                <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>
                                <a class="button button-large button-circle fw-bolder"
                                    style="background-color: #FFD600; color: black;">
                                    <i *ngIf="session.step3.response === '3'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [class.selected-border]="session.step3.response === '4'"
                            [ngStyle]="{'background-color': isCardSelected04 ? '#ffd600' : '#F0F1F2'}"
                            (click)="navigateStepFor('4')">
                                <h4 class="mb-3 fw-bolder tile-question">50 000€ / Mois</h4>
                                <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>
                                <a class="button button-large button-circle fw-bolder"
                                    style="background-color: #FFD600; color: black;">
                                    <i *ngIf="session.step3.response === '4'" class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-md-12 center-both">                                                   
                          <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4">
                                <h4 class="mb-5 pt-3 fw-bolder">Selectionner une somme</h4>
                                <select [(ngModel)]="selectedValue" class="classic fw-bolder mb-5" style="font-size: 35px;">
                                    <option class="fw-bolder" value="5000">5000 € / mois</option>
                                    <option  class="fw-bolder" value="10000">10 000 € / mois</option>
                                    <option  class="fw-bolder" value="20000">20 000 € / mois</option>
                                    <option  class="fw-bolder" value="50000">+ 50 000 € / mois</option>
                                  </select>  
                                  <br>                               
                                <a (click)="navigateStepFor()" class="button button-large button-circle fw-bolder" style="background-color: #FFD600; color: black;"><i class="fa-solid fa-check"></i>Valider</a>
                            </div>
                        </div>
                    </div> -->
                </div>     

                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="width: 37.5%;">                      
                    </div>
                </div>  
                
                <!-- <div class="row align-items-center gy-3">                    
                    <div class="col-lg-12">
                        <div class="skill-progress mb-4" data-percent="90" data-speed="2000" style="--cnvs-progress-height: 36px; --cnvs-progress-rounded: 50rem;">
                            <div class="skill-progress-bar">
                                <div class="skill-progress-percent gradient-blue"></div>
                                <div class="d-flex position-absolute w-100 h-100 px-3 dark justify-content-between align-items-center">
                                    <h5 class="mb-0">Etape 03</h5>
                                    <div class="text-dark">
                                        <small class="fw-semibold"><div class="counter counter-inherit counter-instant"></div></small>
                                    </div>
                                </div>
                            </div>
                        </div>                                    
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>