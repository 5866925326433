import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorService } from '../../services/simulator.service';
import { SimulatorSession } from "../../session/simulator.session";

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent {

  session: any = {};
  error: any;
  constructor(private router: Router, private simulatorSession: SimulatorSession, private simulatorService:SimulatorService) { }

  ngOnInit(): void {
    this.session = this.simulatorSession.getItem('simulator');    
  }

 checkout(price: number) {
  this.session.price = price;  
  this.simulatorSession.setItem('simulator', this.session);
  this.session = this.simulatorSession.getItem('simulator');      
  this.simulatorService.checkout(this.session).subscribe({
    next: (response: any) => {                
      /* success callback */                  
      window.location.href = response.session.url;               ;                
    },
    error: (error: any) => {
      /* error callback */      
      this.error = true;
      setTimeout(() => {
        this.error = null;
      }, 3500); // 500 millisecondes = 0.5 seconde       
    }
  });
 }

}
