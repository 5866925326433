import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimulatorService } from '../../services/simulator.service';
import { jwtDecode } from "jwt-decode";

@Component({
  selector: 'app-success-sale',
  templateUrl: './success-sale.component.html',
  styleUrls: ['./success-sale.component.css']
})
export class SuccessSaleComponent {

  constructor(private route: ActivatedRoute,private simulatorService:SimulatorService) { }


  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    console.log('ID from URL:', id);

    this.decodeToken(id);
  }
  
  decodeToken(token: any): any {
    try {
      const decodedToken: any = jwtDecode(token);

      const expirationTimestamp: number = decodedToken.exp * 1000; // Convertir la date d'expiration en millisecondes
      const currentTimestamp: number = Date.now(); // Obtenir le timestamp actuel en millisecondes


      if (currentTimestamp > expirationTimestamp) {
        console.log('Le lien a expiré.');
      } else {
        console.log('Le lien est toujours valide.');
        // Vous pouvez accéder à d'autres propriétés du token ici
      }

      // console.log(decodedToken);
      // console.log(decodedToken['ref']);
    } catch (error) {
      console.error('Erreur lors du décodage du token:', error);
      return null;
    }
  }


  downloadBusinessFile() {
    // this.simulatorService.getBusinessFile().subscribe({
    //   next: (response: any) => {
    //     const url = window.URL.createObjectURL(response);
    //     const a = document.createElement('a');
    //     document.body.appendChild(a);
    //     a.href = url;
    //     a.download = 'guide-business.pdf'; // Nom du fichier à télécharger
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    //   },
    //   error: (error) => {
    //     console.error('Erreur lors du téléchargement du fichier PDF:', error);
    //   }
    // });
  }
  

  
  

}
