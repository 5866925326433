import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorService } from '../../services/simulator.service';
import { SimulatorSession } from "../../session/simulator.session";

@Component({
  selector: 'app-load-report',
  templateUrl: './load-report.component.html',
  styleUrls: ['./load-report.component.css']
})
export class LoadReportComponent {

  session: any = {};
  error: any;

  constructor(private router: Router, private simulatorSession: SimulatorSession, private simulatorService:SimulatorService) { }

  ngOnInit(): void {    
    // Get session 
    this.session = this.simulatorSession.getItem('simulator');  
    const currentDate = new Date();
    this.session.created = currentDate.getTime();  
    this.simulatorSession.setItem('simulator', this.session);
    this.session = this.simulatorSession.getItem('simulator');    
    if (this.session === null || this.session.step1.check === false) {
      this.router.navigateByUrl('simulator/1');
    } else {           
      this.simulatorService.saveReport(this.session).subscribe({
        next: (response: any) => {          
          this.session.id = response.response.id;
          this.simulatorSession.setItem('simulator', this.session);
          /* success callback */                              ;          
          setTimeout(() => {
            this.router.navigateByUrl('simulator/report/resume');            
          }, 3500); // 500 millisecondes = 0.5 seconde
        },
        error: (error: any) => {
          /* error callback */          
          this.router.navigateByUrl('simulator/email?back=error')          
        }
      });      
    }
  }



}
