<section style="margin-top: -80px;">
    <div class="content-wrap">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <img src="assets/images/logo.jpg" alt="Logo" class="mx-3" style="width: 215px;">
                    <!-- brand men -->
                    <h3 *ngIf="session.gender.response ==='men'" class="display-4 fw-bolder ls1 mb-3 position-relative">
                        On génère ton<br>
                        <span
                            [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                            style="margin-top: 5px; display: inline-block;">guide personnalisé</span>
                    </h3>
                    <!-- brand women -->
                    <h3 *ngIf="session.gender.response ==='women'"
                        class="display-4 fw-bolder ls1 mb-3 position-relative">On génère ton<br>
                        <span
                            [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                            style="margin-top: 5px; display: inline-block;">guide personnalisé</span>
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 text-center mb-5">
                    <div class="col-md-12 center-both">
                        <!-- Vidéo remplaçant le GIF -->
                        <!-- <video autoplay loop muted playsinline style="width: 550px; max-width: 100%;">
                            <source src="assets/images/simulator/video.mp4" type="video/mp4">
                            Votre navigateur ne supporte pas les vidéos HTML5.
                        </video> -->
                        <img src="assets/images/simulator/video-etp.gif"  style="width: 550px; max-width: 100%;">

                        <!-- brand men -->
                        <div *ngIf="session.gender.response ==='men'" class="text-center">
                            <h3 *ngIf="selectedSaleTexts.length > 0" class="mb-0 no-color fw-bolder mb-4"><span
                                    [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }">{{
                                    selectedSaleTexts[currentTextIndex] }}</span></h3>
                        </div>
                        <!-- brand women -->
                        <div *ngIf="session.gender.response ==='women'" class="text-center">
                            <h3 *ngIf="selectedSaleTexts.length > 0" class="mb-0 no-color fw-bolder mb-4"><span
                                    [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }">{{
                                    selectedSaleTexts[currentTextIndex] }}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-simulator-footer></app-simulator-footer>