import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SimulatorComponent } from './simulator/simulator.component';
import { Step01Component } from './simulator/step01/step01.component';
import { Step02Component } from './simulator/step02/step02.component';
import { Step03Component } from './simulator/step03/step03.component';
import { Step04Component } from './simulator/step04/step04.component';
import { Step05Component } from './simulator/step05/step05.component';
import { Step06Component } from './simulator/step06/step06.component';
import { Step07Component } from './simulator/step07/step07.component';
import { Step08Component } from './simulator/step08/step08.component';
import { StepEmailComponent } from './simulator/step-email/step-email.component';
import { LoadReportComponent } from './simulator/load-report/load-report.component';
import { ResumeComponent } from './simulator/resume/resume.component';
import { SuccessSaleComponent } from './simulator/success-sale/success-sale.component';

import { CestnowProductsComponent } from './cestnow-products/cestnow-products.component';

import { ProductsSimulatorComponent } from './products-simulator/products-simulator.component';
import { Bu01Step00Component } from './products-simulator/simulator-business-01/bu-01-step00/bu-01-step00.component';
import { Bu01Step01Component } from './products-simulator/simulator-business-01/bu-01-step01/bu-01-step01.component';
import { Bu01Step02Component } from './products-simulator/simulator-business-01/bu-01-step02/bu-01-step02.component';
import { Bu01Step03Component } from './products-simulator/simulator-business-01/bu-01-step03/bu-01-step03.component';
import { Bu01Step04Component } from './products-simulator/simulator-business-01/bu-01-step04/bu-01-step04.component';
import { Bu01Step05Component } from './products-simulator/simulator-business-01/bu-01-step05/bu-01-step05.component';
import { Bu01Step06Component } from './products-simulator/simulator-business-01/bu-01-step06/bu-01-step06.component';
import { Bu01Step07Component } from './products-simulator/simulator-business-01/bu-01-step07/bu-01-step07.component';
import { Bu01Step08Component } from './products-simulator/simulator-business-01/bu-01-step08/bu-01-step08.component';
import { Bu01Step09Component } from './products-simulator/simulator-business-01/bu-01-step09/bu-01-step09.component';
import { Bu01Step10Component } from './products-simulator/simulator-business-01/bu-01-step10/bu-01-step10.component';
import { Bu01StepEmailComponent } from './products-simulator/simulator-business-01/bu-01-step-email/bu-01-step-email.component';
import { Bu01LoadingComponent } from './products-simulator/simulator-business-01/bu-01-loading/bu-01-loading.component';
import { Bu01CartComponent } from './products-simulator/simulator-business-01/bu-01-cart/bu-01-cart.component';
import { Bu01SucessSaleComponent } from './products-simulator/simulator-business-01/bu-01-sucess-sale/bu-01-sucess-sale.component';
import { CancelSaleSimulatorComponent } from './products-simulator/cancel-sale-simulator/cancel-sale-simulator.component';

import { ProductEbook01Component } from './products-ebook/product-ebook01/product-ebook01.component';
import { ProductEbook02Component } from './products-ebook/product-ebook02/product-ebook02.component';
import { ProductEbook03Component } from './products-ebook/product-ebook03/product-ebook03.component';
import { ProductEbook04Component } from './products-ebook/product-ebook04/product-ebook04.component';

import { SuccessSaleEbookComponent } from './products-ebook/success-sale-ebook/success-sale-ebook.component';
import { CancelSaleEbookComponent } from './products-ebook/cancel-sale-ebook/cancel-sale-ebook.component';


import { TermsComponent } from './terms/terms.component';



const routes: Routes = [
  {
    path: '', component: CestnowProductsComponent,
  },
  {
    path: 'simulator/email', component: StepEmailComponent,
  },
  {
    path: 'business-1/le-guide-ultime', component: ProductsSimulatorComponent,
  },
  {
    path: 'business-1/simulateur/etape/0', component: Bu01Step00Component,
  },
  {
    path: 'business-1/simulateur/etape/1', component: Bu01Step01Component,
  },
  {
    path: 'business-1/simulateur/etape/2', component: Bu01Step02Component,
  },
  {
    path: 'business-1/simulateur/etape/3', component: Bu01Step03Component,
  },
  {
    path: 'business-1/simulateur/etape/4', component: Bu01Step04Component,
  },
  {
    path: 'business-1/simulateur/etape/5', component: Bu01Step05Component,
  },
  {
    path: 'business-1/simulateur/etape/6', component: Bu01Step06Component,
  },
  {
    path: 'business-1/simulateur/etape/7', component: Bu01Step07Component,
  },
  {
    path: 'business-1/simulateur/etape/8', component: Bu01Step08Component,
  },
  {
    path: 'business-1/simulateur/etape/9', component: Bu01Step09Component,
  }, 
  {
    path: 'business-1/simulateur/email', component: Bu01StepEmailComponent,
  },
  {
    path: 'business-1/rapport/loading', component: Bu01LoadingComponent,
  },
  {
    path: 'business-1/rapport', component: Bu01CartComponent,
  },
  {
    path: 'business-1/sucess/:id', component: Bu01SucessSaleComponent,
  },
  {
    path: 'simulator/cancel/:id', component: CancelSaleSimulatorComponent,
  },  
  {
    path: 'ebook/comment-elever-l-amour-propre-et-guérir-l-esprit-le-corps-et-l-ame', component: ProductEbook01Component,
  },
  {
    path: 'ebook/gagner-de-l-argent-sans-te-montrer-guide', component: ProductEbook02Component,
  },
  {
    path: 'ebook/guide-faceless-reels', component: ProductEbook03Component,
  },
  {
    path: 'ebook/guide-faceless-reels-instagram-tiktok', component: ProductEbook04Component,
  },
  {
    path: 'ebook/success/:id', component: SuccessSaleEbookComponent,
  },
  {
    path: 'cancel/:id', component: CancelSaleEbookComponent,
  },
  {
    path: 'mentionslegales', component: TermsComponent,
  },
  {
    path: '**', redirectTo: '', pathMatch: 'full'
  },
];

@NgModule({  
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
