<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <!--Brand men-->
                    <div *ngIf="session.gender.response ==='men'" class="col-12 col-lg-12">
                        <a [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                            routerLink="/business-1/simulateur/etape/9" class="button  button-rounded fw-bolder"><i
                                class="bi-arrow-left-circle-fill"></i></a>
                        <a [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                            routerLink="/business-1/simulateur/etape/9" class="button  button-rounded fw-bolder">10.
                            Adresse Email</a>
                    </div>
                    <!--Brand women-->
                    <div *ngIf="session.gender.response ==='women'" class="col-12 col-lg-12">
                        <a [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                            routerLink="/business-1/simulateur/etape/9" class="button  button-rounded fw-bolder"><i
                                class="bi-arrow-left-circle-fill"></i></a>
                        <a [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                            routerLink="/business-1/simulateur/etape/9" class="button  button-rounded fw-bolder">10.
                            Adresse Email</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="content" class="pt-5">
    <div class="content-wrap py-10">
        <div class="border-bottom py-4">
            <div class="container">
                <!-- Brand men -->
                <div *ngIf="session.gender.response ==='men'" class="text-center mb-5 ">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block"> Ton rapport<br>
                        <span
                            [ngStyle]="{'background-color': session.theme.men.backgroundColor,'color': session.theme.men.textColor}">business
                            personnalisé</span>
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none"> Ton rapport<br>
                        <span
                            [ngStyle]="{'background-color': session.theme.men.backgroundColor,'color': session.theme.men.textColor}">business
                            personnalisé</span>
                    </h6>                    
                </div>
                <!-- Brand women -->
                <div *ngIf="session.gender.response ==='women'" class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative"> Ton rapport<br>
                        <span
                            [ngStyle]="{'background-color': session.theme.women.backgroundColor,'color': session.theme.women.textColor}">business
                            personnalisé</span>
                    </h3>
                    <!-- <p>Nous avons préparé une analyse détaillée spécialement pour ton projet.<br> Entre ton
                        email ci-dessous pour obtenir directement ton rapport personnalisé dans ta boîte de réception.
                    </p> -->
                </div>

                <div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
                    <div class="alert alert-danger" role="alert">
                        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
                    </div>
                </div>

                <div class="row align-items-stretch g-4 text-center mb-5 justify-content-center">
                    <div class="col-md-6 mx-auto">
                        <!-- brand men -->
                        <div *ngIf="session.gender.response ==='men'" class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4" [ngStyle]="{
                                'background-color': isCardSelected ? session.theme.men.backgroundColor : '#F0F1F2',
                                'border': session.stepEmail.response === '1' ? session.theme.men.borderColor : 'none'
                                }">
                                <h4 *ngIf="isCardSelected === false" class="mb-3 pt-3 fw-bolder">Ton adresse e-mail</h4>
                                <h4 *ngIf="isCardSelected === true" class="mb-3 pt-3 fw-bolder text-white">Votre adresse e-mail</h4>
                                <input type="email" [(ngModel)]="email" (ngModelChange)="validateEmail()"
                                    [ngClass]="{'is-invalid': emailInvalid, 'is-empty': emailEmpty}"
                                    class="form-control" id="emailInput" placeholder="Entrez votre email">
                                <br>

                                <p *ngIf="isCardSelected === false" class="custom-small-text-rapport-email">Nous avons préparé une analyse détaillée spécialement pour ton projet. Entre ton
                                    email ci-dessous pour obtenir directement ton rapport personnalisé dans ta boîte de réception.
                                </p> 
                                <p *ngIf="isCardSelected === true" class="custom-small-text-rapport-email text-white">Nous avons préparé une analyse détaillée spécialement pour ton projet. Entre ton
                                    email ci-dessous pour obtenir directement ton rapport personnalisé dans ta boîte de réception.
                                </p> 

                                <a (click)="generateReport()" class="button button-large button-circle fw-bolder"
                                    [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                                    [ngClass]="{'hovered': isHovered[0]}" (mouseenter)="onMouseEnter(0)"
                                    (mouseleave)="onMouseLeave(0)">
                                    <i class="fa-solid fa-check"></i>
                                    Obtenez le rapport
                                </a>

                                <p *ngIf="isCardSelected === false" class="pt-3 custom-small-text">                                                                        
                                    Pas de souci, on déteste les spams autant que toi. Ton email restera confidentiel et ne sera jamais partagé avec qui que ce soit. Tu ne recevras que des informations utiles pour ton projet.
                                </p>
                              
                                <p *ngIf="isCardSelected === true" class="pt-3 custom-small-text text-white">                                                                        
                                    Pas de souci, on déteste les spams autant que toi. Ton email restera confidentiel et ne sera jamais partagé avec qui que ce soit. Tu ne recevras que des informations utiles pour ton projet.
                                </p>
                                
                            </div>
                        </div>
                        <!-- brand women -->
                        <div *ngIf="session.gender.response ==='women'"
                            class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4" [ngStyle]="{
                                'background-color': isCardSelected ? session.theme.women.backgroundColor : '#F0F1F2',
                                'border': session.stepEmail.response === '1' ? session.theme.women.borderColor : 'none'
                                }">
                                <h4 class="mb-3 pt-3 fw-bolder">Ton adresse e-mail</h4>
                                <input type="email" [(ngModel)]="email" (ngModelChange)="validateEmail()"
                                    [ngClass]="{'is-invalid': emailInvalid, 'is-empty': emailEmpty}"
                                    class="form-control" id="emailInput" placeholder="Entrez votre email">
                                <br>                                
                                <p *ngIf="isCardSelected === false" class="custom-small-text-rapport-email">Nous avons préparé une analyse détaillée spécialement pour ton projet. Entre ton
                                    email ci-dessous pour obtenir directement ton rapport personnalisé dans ta boîte de réception.
                                </p> 
                                <p *ngIf="isCardSelected === true" class="custom-small-text-rapport-email text-white">Nous avons préparé une analyse détaillée spécialement pour ton projet. Entre ton
                                    email ci-dessous pour obtenir directement ton rapport personnalisé dans ta boîte de réception.
                                </p> 

                                <a (click)="generateReport()" class="button button-large button-circle fw-bolder"
                                    [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                                    [ngClass]="{'hovered': isHovered[0]}" (mouseenter)="onMouseEnter(0)"
                                    (mouseleave)="onMouseLeave(0)">
                                    <i class="fa-solid fa-check"></i>
                                    Obtenez le rapport
                                </a>

                                <p *ngIf="isCardSelected === false" class="pt-3 custom-small-text">                                                                        
                                    Pas de souci, on déteste les spams autant que toi. Ton email restera confidentiel et ne sera jamais partagé avec qui que ce soit. Tu ne recevras que des informations utiles pour ton projet.
                                </p>
                              
                                <p *ngIf="isCardSelected === true" class="pt-3 custom-small-text text-white">                                                                        
                                    Pas de souci, on déteste les spams autant que toi. Ton email restera confidentiel et ne sera jamais partagé avec qui que ce soit. Tu ne recevras que des informations utiles pour ton projet.
                                </p>                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="progress">
                    <!-- Brand men -->
                    <div *ngIf="session.gender.response ==='men'"
                        [ngStyle]="{'background-color': session.theme.men.backgroundColor}" class="progress-bar"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="width: 98%;">
                    </div>
                    <!-- Brand women -->
                    <div *ngIf="session.gender.response ==='women'"
                        [ngStyle]="{'background-color': session.theme.women.backgroundColor}" class="progress-bar"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="width: 98%;">
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>