import { Component } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SimulatorSession } from "../../session/simulator.session";

@Component({
  selector: 'app-step-email',
  templateUrl: './step-email.component.html',
  styleUrls: ['./step-email.component.css']
})
export class StepEmailComponent {

  session: any = {};
  isCardSelected: boolean = false;
  email: string = '';
  emailInvalid: boolean = false;
  emailEmpty: boolean = true;
  error: any;

  constructor(private router: Router, private route: ActivatedRoute, private simulatorSession: SimulatorSession) { }

  ngOnInit(): void {
    // Get session 
    this.session = this.simulatorSession.getItem('simulator');
    this.email = this.session.stepEmail.email;
    if (this.session === null || this.session.step1.check === false) {
      this.router.navigateByUrl('simulator/1');
    }

    this.route.queryParams.subscribe(params => {
      if (params['back']) {
        // Logique en cas d'erreur        
        this.error = true;
        setTimeout(() => {
          this.error = null;
        }, 5500); // 500 millisecondes = 0.5 seconde   
      }
    });

  }

  validateEmail(): void {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.emailEmpty = this.email.length === 0;
    this.emailInvalid = !this.emailEmpty && !emailPattern.test(this.email);
  }

  generateReport() {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailPattern.test(this.email)) {
      // Logique supplémentaire pour un email valide
      this.isCardSelected = true;
      this.session.stepEmail.response = '1';
      this.session.stepEmail.check = true;
      this.session.stepEmail.email = this.email;
      this.simulatorSession.setItem('simulator', this.session);
      setTimeout(() => {
        this.router.navigateByUrl('simulator/generate/report');
      }, 500); // 500 millisecondes = 0.5 seconde    
    } else {
      // Logique supplémentaire pour un email invalide
      this.isCardSelected = false;
      this.session.stepEmail.response = '';
      this.session.stepEmail.check = false;
      this.session.stepEmail.email = '';
      this.simulatorSession.setItem('simulator', this.session);
    }
  }

}
