<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-lg-12">
                        <div class="row g-4 align-items-center justify-content-center">
                            <div id="tab-reservation" class="nav nav-pills flex-column flex-lg-row" role="tablist">
                                <a class="nav-link active ms-0 fw-bolder" routerLink="/simulator/email"><i
                                        class="bi-arrow-left-circle-fill"></i></a>
                                <a class="nav-link active ms-0 mt-2 mt-lg-0 ms-lg-3  fw-bolder"
                                    routerLink="/simulator/report/resume">Votre rapport</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="content" class="pt-5">
    <div class="content-wrap py-10">
        <div class="border-bottom py-4">
            <div class="container">
                
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative">Votre <span
                            class="gradient-text gradient-color">Rapport</span> business personnaliser </h3>
                    <p class="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                        doloremque laudantium,</p>
                    <!-- <a href="#" class="button button-border button-large rounded-pill m-0">Etape 8 - Type de business ?</a> -->
                </div>
             
                <div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
                    <div class="alert alert-danger" role="alert">
                        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
                    </div>
                </div>

                <div class="row align-items-stretch g-4 center mb-5">
                    <div class="col-md-4">                                                 
                          <div class="card bg-secondary bg-opacity-10 border-0 offre-populaire">
                            <div class="bande-jaune"></div> <!-- Ajoutez cet élément -->
                            <div class="card-body p-4 pt-5 pointer">
                              <h4 class="mb-3 fw-bolder tile-question">27€ / rapport </h4>
                              <p class="mb-3 text-muted">Lorem ipsum dolor sit amet, consectetur</p>
                              <a (click)="checkout(1)" class="button button-large button-circle fw-bolder">
                                <i class="fa-solid fa-check"></i>
                                Sélectionner
                              </a>
                            </div>
                          </div>                          
                    </div>
                    <div class="col-md-4 text-center pt-5">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer">
                                <h4 class="mb-3 fw-bolder tile-question">15€ / rapport</h4>
                                <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>
                                <a (click)="checkout(2)" class="button button-large button-circle fw-bolder">
                                    <i class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>              
                    <div class="col-md-4 text-center pt-5">
                        <div class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer">
                                <h4 class="mb-3 fw-bolder tile-question">9€ / rapport</h4>
                                <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>
                                <a (click)="checkout(3)" class="button button-large button-circle fw-bolder">
                                    <i class="fa-solid fa-check"></i>
                                    Sélectionner
                                </a>
                            </div>
                        </div>
                    </div>                                       
                </div>           
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                        aria-valuemax="100" style="width: 95%;">
                    </div>
                </div>
             
            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>






