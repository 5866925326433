import { Component } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SimulatorSessionv1 } from "../../../session/simulatorv1.session";
import { FacebookService } from '../../../services/facebook.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-bu-01-step-email',
  templateUrl: './bu-01-step-email.component.html',
  styleUrls: ['./bu-01-step-email.component.css']
})
export class Bu01StepEmailComponent {

  session: any = {};  // Contient les données de la session utilisateur
  idSession: string = 'simulateur-bu-01';  // Identifiant de la session
  isCardSelected: boolean = false;  // Indique si une carte est sélectionnée (utilisé pour les styles)
  email: string = 'jaouad.essika@gmail.com';  // Email de l'utilisateur (par défaut un email est fourni ici pour exemple)
  emailInvalid: boolean = false;  // Indique si l'email est invalide
  emailEmpty: boolean = true;  // Indique si l'email est vide
  error: any;  // Gestion des erreurs
  isHovered: boolean[] = [false, false];  // Gestion des états de survol pour les boutons

  /**
   * Constructeur injectant les services nécessaires :
   * - Router : pour la navigation
   * - ActivatedRoute : pour récupérer les paramètres de la route
   * - SimulatorSessionv1 : pour gérer les données de session utilisateur
   * - FacebookService : pour interagir avec le Pixel Facebook
   */
  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private simulatorSessionv1: SimulatorSessionv1, 
    private facebookService: FacebookService
  ) {}

  /**
   * Méthode appelée au démarrage du composant. 
   * - Récupère la session utilisateur
   * - Initialise le Pixel Facebook
   * - Gère les erreurs passées via les paramètres de la route
   */
  ngOnInit(): void {
    // Récupération de la session utilisateur
    this.session = this.simulatorSessionv1.getItem(this.idSession);

    // Vérifie si la session est valide, sinon redirige vers la première étape du simulateur
    if (this.session === null || !this.session.step2.check || !this.session.step3.check || !this.session.step4.check || 
        !this.session.step5.check || !this.session.step6.check || !this.session.step7.check || !this.session.step8.check || 
        !this.session.step9.check) {
      this.router.navigateByUrl('/business-1/simulateur/etape/1');
    }

    // Initialise le Pixel Facebook
    this.facebookService.init(environment.products.simulatorbu01.pixel);

    // Gestion des erreurs passées via les paramètres de la route
    this.route.queryParams.subscribe(params => {
      if (params['back']) {
        this.error = true;
        setTimeout(() => {
          this.error = null;
        }, 5500); // Cache l'erreur après 5,5 secondes
      }
    });
  }

  /**
   * Valide l'email en utilisant un regex.
   * Met à jour les états `emailEmpty` et `emailInvalid` selon la validité de l'email.
   */
  validateEmail(): void {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.emailEmpty = this.email.length === 0;
    this.emailInvalid = !this.emailEmpty && !emailPattern.test(this.email);
  }

  /**
   * Génère le rapport si l'email est valide.
   * - Met à jour la session avec l'email validé
   * - Envoie un événement de suivi à Facebook
   * - Redirige l'utilisateur vers la page de chargement du rapport
   */
  generateReport(): void {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailPattern.test(this.email)) {
      // Si l'email est valide
      this.isCardSelected = true;
      this.session.stepEmail.check = true;
      this.session.stepEmail.response = this.email;
      this.simulatorSessionv1.setItem(this.idSession, this.session);

      // Envoie l'événement "EmailSubmitted" au Pixel Facebook
      this.facebookService.track('EmailSubmitted');
      this.facebookService.sendEventToFacebook(environment.products.simulatorbu01.pixel, environment.products.simulatorbu01.accessToken, 'EmailSubmitted', this.email);

      // Redirige vers la page de chargement du rapport après 500ms
      setTimeout(() => {
        this.router.navigateByUrl('business-1/rapport/loading');
      }, 500);
    } else {
      // Si l'email est invalide
      this.isCardSelected = false;
      this.session.stepEmail.response = '';
      this.session.stepEmail.check = false;
      this.simulatorSessionv1.setItem(this.idSession, this.session);
    }
  }

  /**
   * Gère l'état de survol des boutons.
   * @param index L'index du bouton survolé
   */
  onMouseEnter(index: number) {
    this.isHovered[index] = true;
  }

  /**
   * Réinitialise l'état de survol des boutons.
   * @param index L'index du bouton dont le survol est terminé
   */
  onMouseLeave(index: number) {
    this.isHovered[index] = false;
  }
}
