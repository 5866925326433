<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>



<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-lg-12">
                        <div class="d-flex align-items-center justify-content-center nav nav-pills">
                            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                            <a *ngIf="showCountdown === true" (click)="scrollToElement('price')"
                                style="background-color: red; color: white;"
                                class="button button-large button-circle fw-bolder d-none d-md-block pointer">
                                <i class="bi-clock-fill"></i>
                                {{formatTime(timeLeft) }}
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <img src="assets/images/ebooks/ebook03/instagram_icon.webp" alt="Logo"
                                class="mx-3 d-none d-md-block" style="width: 50px;">
                            <img src="assets/images/ebooks/ebook04/tiktok_cestnow.webp" alt="Logo"
                                class="mx-3 d-none d-md-block" style="width: 50px;">

                            <!-- <a (click)="scrollToElement('price')" class="button button-large button-circle fw-bolder pointer" style="background-color: #9A42A4; color: white;">
                                <i class="bi-cloud-download"></i>
                                Crée ta communauté
                            </a> -->

                            <!-- <a (click)="scrollToElement('price')"
                                class="button button-large button-circle fw-bolder pointer"
                                style="background: linear-gradient(to right, #9A42A4 50%, #FF004F 50%); color: white; border: none;">
                                <i class="bi-cloud-download"></i>
                                Crée ta communauté
                            </a> -->
                            <a (click)="scrollToElement('price')"
                                class="button button-large button-circle fw-bolder pointer"
                                style="background: black; color: white;">
                                <i class="bi-cloud-download"></i>
                                Crée ta communauté
                            </a>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pt-5">
    <div class="content-wrap py-10">
        <div class="page-section">
            <div class="container">
                <div class="clear"></div>
                <div class="text-center">

                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Construit ta communauté<br> <span
                            style="color: #ffffff; background-color: #9A42A4;">Instagram</span> et <span
                            style="color: #ffffff; background-color: #FF004F;">TikTok</span> aujourd'hui <br>
                        sans montrer ton visage
                    </h3>
                    <!-- <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                les voyages <br> <span
                            style="color: #ffffff; background-color: #9A42A4;">de Alya</span> au tour du monde
                    </h3> -->

                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Construit ta communauté
                        <span style="color: #ffffff; background-color: #9A42A4;">Instagram</span> et
                        <span style="color: #ffffff; background-color: #FF004F;">TikTok</span>
                        aujourd'hui sans montrer ton visage
                    </h3>

                    <br>

                    <!-- <img src="assets/images/ebooks/ebook04/photo-alya.jpg"> -->
                    <div class="wistia_responsive_padding mb-5" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper"
                            style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <div class="wistia_embed wistia_async_c5adif33kc seo=true videoFoam=true"
                                style="height:100%;position:relative;width:100%">
                                <div class="wistia_swatch"
                                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                    <img src="https://fast.wistia.com/embed/medias/c5adif33kc/swatch"
                                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
                                        aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="scroll">
                        <div class="m-scroll">
                            <span><img width="120px"
                                    src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo_TV_2015.png"></span>
                            <span><img width="120px"
                                    src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo_TV_2015.png"></span>
                            <span><img width="120px"
                                    src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo_TV_2015.png"></span>
                            <span><img width="120px"
                                    src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo_TV_2015.png"></span>
                        </div>
                        <div class="m-scroll reverse">
                            <span><img width="120px"
                                    src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo_TV_2015.png"></span>
                            <span><img width="120px"
                                    src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo_TV_2015.png"></span>
                            <span><img width="120px"
                                    src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo_TV_2015.png"></span>
                            <span><img width="120px"
                                    src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo_TV_2015.png"></span>
                        </div>
                    </div>


                    <a *ngIf="showCountdown === true" (click)="scrollToElement('price')"
                        style="background-color: red; color: white;"
                        class="button button-large button-circle fw-bolder pointer">
                        <i class="bi-clock-fill"></i>
                        {{formatTime(timeLeft) }}
                    </a>
                    <a *ngIf="showCountdown === false" (click)="scrollToElement('price')"
                        class="button button-large button-circle fw-bolder pointer"
                        style="background-color: #000000; color: white;">
                        <i class="bi-cloud-download"></i>
                        Crée ta communauté
                    </a>
                    <p class="pt-4">
                        Développe ton influence en ligne en toute discrétion : Découvre les secrets pour engager et
                        inspirer sans jamais montrer ton visage.
                    </p>
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 *ngIf="showCountdown === true" class="mb-0 fw-bolder d-none d-md-block"><span class="no-color"
                            style="background-color: #ffd600; color: #000000;">-80% DE RÉDUCTION AUJOURD'HUI</span><br>
                        SUR TES GUIDES INSTAGRAM ET TIKTOK</h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 *ngIf="showCountdown === true" class="mb-0 fw-bolder d-block d-md-none"><span class="no-color"
                            style="background-color: #ffd600; color: #000000;">-80% DE RÉDUCTION AUJOURD'HUI</span> SUR
                        TES GUIDES INSTAGRAM ET TIKTOK</h3>

                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 *ngIf="showCountdown === false" class="mb-0 fw-bolder d-none d-md-block"><span class="no-color"
                            style="background-color: #ffd600; color: #000000;">-70% DE RÉDUCTION AUJOURD'HUI</span><br>
                        SUR TES GUIDES INSTAGRAM ET TIKTOK</h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 *ngIf="showCountdown === false" class="mb-0 fw-bolder d-block d-md-none"><span class="no-color"
                            style="background-color: #ffd600; color: #000000;">-70% DE RÉDUCTION AUJOURD'HUI</span> SUR
                        TES GUIDES INSTAGRAM ET TIKTOK</h3>
                    <br>
                    <a *ngIf="showCountdown === true" (click)="scrollToElement('price')"
                        style="background-color: red; color: white;"
                        class="button button-large button-circle fw-bolder pointer">
                        <i class="bi-clock-fill"></i>
                        {{formatTime(timeLeft) }}
                    </a>
                    <a *ngIf="showCountdown === false" (click)="scrollToElement('price')"
                        class="button button-large button-circle fw-bolder pointer"
                        style="background-color: #000000; color: white;">
                        <i class="bi-cloud-download"></i>
                        Crée ta communauté
                    </a>

                    <div class="row justify-content-center pt-5">
                        <div class="col-lg-8">
                            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                            <p style="text-align: justify;" class="d-none d-md-block">
                                Stratégies Avancées : Découvre des astuces pour améliorer la qualité de tes réels et
                                vidéos TikTok, des techniques de montage aux stratégies de contenu sans visage.
                                <br>
                                Optimisation SEO : Apprends tout sur l'utilisation stratégique des légendes, des
                                hashtags, des mots-clés et des principes de SEO pour maximiser la portée de tes réels et
                                vidéos TikTok.
                                <br>
                                Tendances et Engagement : Familiarise-toi avec les audios tendance, les hooks captivants
                                et des frameworks d'offres qui captureront l'attention de ton audience cible.
                                <br>
                                <br>
                                IA : avatars, musique et outils essentiels pour développer ta communauté.
                                <br>
                                <br>
                                Crée des réels et vidéos TikTok qui non seulement atteignent ton public mais aussi
                                engagent et convertissent.
                                <br>
                                Ces guides sont parfaits pour toi si tu cherches à développer ta présence en ligne de
                                manière discrète mais efficace. Que tu sois au début de ton aventure sur Instagram et
                                TikTok ou que tu cherches à affiner tes compétences existantes, ces guides te fourniront
                                les outils nécessaires pour te démarquer tout en protégeant ton identité.
                                <br>
                                <br>
                                Idéal pour les personnes souhaitant montrer une passion, se lancer dans la création de
                                contenu et valoriser la confidentialité tout en aspirant à un impact maximal sur
                                Instagram et TikTok.
                                <br>
                                <br>
                            </p>
                            <!-- Contenu visible uniquement sur les smartphones -->
                            <p class="d-block d-md-none">
                                Découvre des astuces pour améliorer la qualité de tes réels et vidéos TikTok, du montage
                                aux stratégies de contenu sans visage. Apprends à optimiser tes réels et vidéos avec des
                                hashtags et des mots-clés pour maximiser ta portée. Familiarise-toi avec les audios
                                tendance et les hooks captivants pour capter l'attention de ton audience.
                                <br><br>
                                Ces guides sont parfaits pour les femmes entrepreneures qui veulent développer leur
                                présence en ligne de manière discrète mais efficace, tout en protégeant leur identité.
                            </p>

                            <h3 class="fw-bold ">Transforme ton approche des réels et vidéos TikTok avec les "Guides
                                Faceless" et commence à créer des contenus qui parlent à ton audience sans jamais
                                révéler qui tu es.</h3>

                            <a (click)="scrollToElement('price')"
                                class="button button-large button-circle fw-bolder pointer"
                                style="background-color: #000000; color: white;">
                                <i class="bi-cloud-download"></i>
                                Crée ta communauté
                            </a>
                        </div>
                    </div>
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="fw-bolder pt-6 d-none d-md-block">On te présente <span class="no-color"
                            style="background-color: #ffd600; color: #000000;">Melissa </span>, une<br><span
                            class="no-color" style="background-color: #ffd600; color: #000000;">Intelligence
                            artificielle</span>
                        créatrice de contenu sur Instagram et TikTok !</h3>

                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="fw-bolder pt-6 d-block d-md-none"><span class="no-color"
                            style="background-color: #ffd600; color: #000000;">Melissa </span> une <span
                            class="no-color" style="background-color: #ffd600; color: #000000;">Intelligence
                            artificielle</span>
                        créatrice de contenu sur Instagram et TikTok !</h3>

                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <img src="assets/images/ebooks/ebook03/mood.png" class="mb-4 pt-2 d-none d-md-block">
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <img src="assets/images/ebooks/ebook03/mood-port.png" class="mb-2 pt-2 d-block d-md-none">
                    <br>

                    <h4 class="fw-bold">Et toi, prête à créer du contenu sans montrer ton visage comme notre IA Melissa
                        ?</h4>

                    <a (click)="scrollToElement('price')" class="button button-large button-circle fw-bolder pointer"
                        style="background-color: #000000; color: white;">
                        <i class="bi-cloud-download"></i>
                        Crée ta communauté
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>


<section style="margin-top: -90px;">
    <div class="content-wrap">
        <div id="section-services" class="page-section">
            <div class="container">
                <div class="heading-block mb-5 text-center border-bottom-0">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h2 class="text-transform-none fw-bolder d-none d-md-block"
                        style="font-size: 40px; line-height: 1.2; letter-spacing: -1px;">
                        Chaque étape pour créer ta communauté <br><span style="color: white; background-color: #9A42A4;"
                            class="fw-bolder">Instagram</span> et <span
                            style="background-color: #FF004F; color: #ffffff;">TikTok</span> aujourd'hui
                    </h2>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="text-transform-none fw-bolder d-block d-md-none">
                        Chaque étape pour créer ta communauté <span style="color: white; background-color: #9A42A4;"
                            class="fw-bolder">Instagram</span> et <span
                            style="background-color: #FF004F; color: #ffffff;">TikTok</span> aujourd'hui
                    </h3>
                </div>

                <div class="row justify-content-center mt-lg-6 mt-5">
                    <div class="w-100"></div>
                    <div class="col-lg-12">
                        <div class="row align-items-stretch g-4">

                            <div class="col-xl-7">
                                <div class="d-flex p-5 position-relative bg-contrast-200 flex-column h-100 overflow-hidden"
                                    style="border-radius: 20px;">
                                    <div class="row">
                                        <div class="col-xl-8 col-md-8">
                                            <h4 class="fw-bolder">Maîtrisez l'Art des Réels et Vidéos TikTok : Captivez
                                                sans Montrer votre Visage</h4>
                                            <hr>
                                            <p class="mb-5" style="text-align: justify;">
                                                Découvre comment créer des Réels captivants et des vidéos TikTok
                                                engageantes <strong>sans montrer ton visage</strong> ! Grâce à des
                                                séquences B-Roll et des <strong>outils d'intelligence
                                                    artificielle</strong>, tu apprendras à produire des contenus
                                                engageants tout en restant anonyme. Ces guides t'offrent toutes les
                                                <strong>astuces</strong> pour <strong>optimiser ton contenu</strong> et
                                                atteindre une audience plus large <strong>sans sacrifier ta vie
                                                    privée</strong>.
                                                <br>
                                                <span style="color: #9A42A4;" class="fw-bolder"><a
                                                        style="color: #9A42A4;" (click)="scrollToElement('price')"
                                                        class="pointer">lire la suite&ensp;&ensp;&ensp;</a> <i
                                                        class="bi-arrow-down-circle-fill"></i></span>
                                            </p>
                                        </div>
                                    </div>
                                    <!-- Contenu visible uniquement sur les smartphones -->
                                    <img src="assets/images/ebooks/ebook03/faceless-1-1.png"
                                        style="position: absolute; right: -38px; bottom: -40px; transform: rotate(0deg); width: 380px; height: 380px; z-index: auto;"
                                        alt="Création de Réels et Vidéos TikTok sans visage" class="d-block d-md-none">
                                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                    <img src="assets/images/ebooks/ebook03/faceless-1.png"
                                        style="position: absolute; right: -38px; bottom: -40px; transform: rotate(0deg); width: 380px; height: 380px; z-index: auto;"
                                        alt="Guide pour Réels et Vidéos TikTok anonymes" class="d-none d-md-block">
                                </div>
                            </div>

                            <div class="col-xl-5">
                                <div class="d-flex p-5 position-relative bg-contrast-200 flex-column h-100 overflow-hidden"
                                    style="border-radius: 20px;">
                                    <div>
                                        <h4 class="fw-bolder">Capturez l'Attention en 3 Secondes : Accroches et CTA
                                            Puissants</h4>
                                        <hr>
                                    </div>
                                    <p class="mb-5">
                                        <strong>Attire l'attention</strong> dès les premières secondes ! Nos guides te
                                        donnent des <strong>accroches irrésistibles</strong> et des <strong>appels à
                                            l'action stratégiques</strong> pour capter l'intérêt de tes spectateurs sur
                                        Instagram et TikTok. Apprends à utiliser des <strong>CTA efficaces</strong> pour
                                        <strong>augmenter l'engagement</strong> et convertir tes followers en
                                        <strong>clients fidèles</strong>.
                                        <br>
                                        <span style="color: #9A42A4;" class="fw-bolder"><a style="color: #9A42A4;"
                                                (click)="scrollToElement('price')" class="pointer">lire la
                                                suite&ensp;&ensp;&ensp;</a> <i
                                                class="bi-arrow-down-circle-fill"></i></span>
                                    </p>

                                    <img src="assets/images/ebooks/ebook03/faceless-2.png"
                                        style="position: absolute; right: -50px; bottom: -40px; transform: rotate(0deg); width: 380px; height: 380px; z-index: auto;"
                                        alt="Stratégies d'accroche pour Réels et Vidéos TikTok">
                                </div>
                            </div>

                            <div class="col-xl-5">
                                <div class="d-flex p-5 position-relative bg-contrast-200 flex-column h-100 overflow-hidden"
                                    style="border-radius: 20px;">
                                    <div class="position-relative z-1">
                                        <div>
                                            <h4 class="fw-bolder">Optimisez vos Réels et Vidéos TikTok : Boostez votre
                                                Portée et Gagnez en Visibilité</h4>
                                            <hr>
                                        </div>
                                        <p class="mb-5">
                                            <strong>Booste la visibilité</strong> de tes Réels et vidéos TikTok ! Avec
                                            nos guides, tu découvriras comment <strong>utiliser des audios
                                                tendance</strong>, <strong>créer des photos de couverture
                                                accrocheuses</strong> et <strong>optimiser tes textes</strong> pour
                                            atteindre un public plus large. <strong>Analyse tes insights</strong> et
                                            ajuste ta stratégie pour des <strong>résultats encore meilleurs</strong>.
                                            <br>
                                            <span style="color: #9A42A4;" class="fw-bolder"><a style="color: #9A42A4;"
                                                    (click)="scrollToElement('price')" class="pointer">lire la
                                                    suite&ensp;&ensp;&ensp;</a> <i
                                                    class="bi-arrow-down-circle-fill"></i></span>
                                        </p>
                                    </div>
                                    <img src="assets/images/ebooks/ebook03/faceless-3.png"
                                        style="position: absolute; right: -50px; bottom: -40px; transform: rotate(0deg); width: 400px; height: 400px; z-index: auto;"
                                        alt="Optimisation des Réels et Vidéos TikTok pour Instagram">
                                </div>
                            </div>

                            <div class="col-xl-7">
                                <div class="d-flex position-relative h-100 min-vh-25 overflow-hidden"
                                    style="border-radius: 20px;">
                                    <img src="assets/images/ebooks/ebook03/faceless-4.jpg"
                                        alt="Monétisation des Réels et Vidéos TikTok"
                                        class="object-cover w-100 h-100 position-absolute">
                                    <div class="position-relative z-1 mt-5 ms-5">
                                        <h4 class="fw-bolder">Transformez vos Réels et Vidéos TikTok en Revenus :
                                            Monétisez avec Succès</h4>
                                        <hr>
                                        <p class="mb-5">
                                            <strong>Monétise tes Réels et vidéos TikTok</strong> ! Apprends à
                                            <strong>créer du contenu partageable et sauvegardable</strong> qui attire et
                                            engage ton audience. Utilise nos astuces pour <strong>intégrer des appels à
                                                l'action efficaces</strong> et diriger tes spectateurs vers tes
                                            <strong>produits ou services</strong> pour maximiser tes revenus.
                                            <br>
                                            <span style="color: #9A42A4;" class="fw-bolder"><a style="color: #9A42A4;"
                                                    (click)="scrollToElement('price')" class="pointer">lire la
                                                    suite&ensp;&ensp;&ensp;</a> <i
                                                    class="bi-arrow-down-circle-fill"></i></span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="card rounded-xxl bg-contrast-200 border-0">
                                    <div class="card-body p-5">
                                        <div class="row align-items-center">
                                            <div class="col-md-6">
                                                <h4 class="fw-bolder">Restez Anonyme, Soyez Présent : Protégez votre Vie
                                                    Privée sur Instagram et TikTok</h4>
                                                <hr>
                                                <p>
                                                    <strong>Préserve ton anonymat</strong> tout en étant présent sur
                                                    Instagram et TikTok ! Nos guides te montrent comment
                                                    <strong>utiliser des avatars animés</strong>, <strong>des voix
                                                        off</strong> et d'autres techniques pour rester anonyme. Profite
                                                    de <strong>tous les avantages des réseaux sociaux</strong> sans
                                                    compromettre ta vie privée.
                                                    <br>
                                                    <span style="color: #9A42A4;" class="fw-bolder"><a
                                                            style="color: #9A42A4;" (click)="scrollToElement('price')"
                                                            class="pointer">lire la suite&ensp;&ensp;&ensp;</a> <i
                                                            class="bi-arrow-down-circle-fill"></i></span>
                                                </p>
                                            </div>
                                            <div class="col-md-6">
                                                <img src="assets/images/ebooks/ebook03/faceless-5.png"
                                                    alt="Gestion de l'anonymat sur Instagram et TikTok">
                                            </div>
                                        </div>
                                        <div class="line border-black border-width-2"></div>
                                        <div class="row gy-5">
                                            <div class="col-lg-3">
                                                <img src="assets/images/ebooks/ebook04/instagram-tiktok-faceless-01.png"
                                                    width="180px" alt="Guide Instagram et TikTok sans visage">
                                            </div>

                                            <div class="col-lg-9">
                                                <h2><strong>Transforme ton avenir avec l'IA</strong></h2>
                                                <p class="mb-0">
                                                    D'ici 2030, l'intelligence artificielle aura bouleversé plus de
                                                    <strong>375 millions d'emplois</strong>. Ne reste pas spectateur !
                                                    Grâce à nos guides, découvre comment exploiter ces technologies pour
                                                    <strong>bâtir une communauté Instagram et TikTok solide</strong>,
                                                    tout en gardant ton anonymat. Rejoins les pionniers qui ont déjà
                                                    pris une longueur d'avance. <strong>Télécharge tes guides
                                                        maintenant</strong> et deviens un acteur clé dans l'ère
                                                    numérique.
                                                </p>
                                                <br>
                                                <a *ngIf="showCountdown === true" (click)="scrollToElement('price')"
                                                    style="background-color: red; color: white;"
                                                    class="button button-large button-circle fw-bolder pointer">
                                                    <i class="bi-clock-fill"></i>
                                                    {{formatTime(timeLeft) }}
                                                </a>
                                                <a (click)="scrollToElement('price')"
                                                    class="button button-large button-circle fw-bolder pointer"
                                                    style="background-color: #000000; color: #ffffff;">
                                                    <i class="bi-cloud-download"></i>
                                                    Créer ta communauté
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section style="margin-top: -80px;">
    <div class="content-wrap">
        <div class="container">
            <div class="row g-0">
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-01.png"
                            alt="Icône de l'IA ChatGPT">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-02.png"
                            alt="Icône de l'IA MidJourney">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-03.png"
                            alt="Icône de Canva">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-04.png"
                            alt="Icône de Stable Diffusion">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-05.png"
                            alt="Icône de DALL-E">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-06.png"
                            alt="Icône de Copilot">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-07.png"
                            alt="Icône de Copy.ai">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-08.png"
                            alt="Icône de Jasper">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-09.png"
                            alt="Icône de Synthesia">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-10.png"
                            alt="Icône de Frase">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-11.png"
                            alt="Icône de Surfer SEO">
                    </a>
                </div>
            </div>

            <div class="row g-0">
                <div class="col d-none d-md-flex">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-24.png"
                            alt="Icône de Lumen5">
                    </a>
                </div>
                <div class="col-lg-10 d-lg-flex justify-content-between align-items-center px-5 py-4 py-lg-0">
                    <div class="mb-2 mb-lg-0">
                        <h2 class="mb-0 no-color fw-bolder">+250 outils IA à ton service</h2>
                        <p class="mb-0 text-muted fw-normal">
                            L’extrême technologie : Exploite des <strong>outils d'intelligence artificielle</strong>
                            pour créer du contenu et développer ta <strong>communauté Instagram et TikTok</strong> sans
                            montrer ton visage. Profite de leur efficacité pour rester compétitif.
                        </p>
                    </div>
                    <a (click)="scrollToElement('price')" class="button button-large button-circle fw-bolder pointer"
                        style="background-color: #000000; color: white;">
                        <i class="bi-cloud-download"></i> Crée ta communauté
                    </a>
                </div>
                <div class="col d-none d-lg-flex">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-12.png"
                            alt="Icône de Otter.ai">
                    </a>
                </div>
            </div>

            <div class="row g-0">
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-13.png"
                            alt="Icône de Descript">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-14.png"
                            alt="Icône de Midjourney">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-15.png"
                            alt="Icône de Jarvis">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-16.png"
                            alt="Icône de Flick">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-17.png"
                            alt="Icône de Play.ht">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-18.png"
                            alt="Icône de Copy.ai">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-19.png"
                            alt="Icône de Canva">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-20.png"
                            alt="Icône de Loom">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-21.png"
                            alt="Icône de Tidio">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-22.png"
                            alt="Icône de Kapwing">
                    </a>
                </div>
                <div class="col">
                    <a (click)="scrollToElement('price')" class="pointer">
                        <img class="op-ts op-1 h-op-08" src="assets/images/simulator/ia/ia-icon-23.png"
                            alt="Icône de Runway ML">
                    </a>
                </div>
            </div>

        </div>
    </div>
</section>


<section id="price" style="margin-top: -100px;">
    <div class="content-wrap">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-10 text-center mb-5">
                    <h3 class="h1 fw-bolder mb-3">Tes guides pour créer ta communauté <br><span
                            style="background-color: #9A42A4; color: #ffffff;">Instagram</span> et <span
                            style="background-color: #FF004F; color: #ffffff;">TikTok</span>
                        <br> sans montrer ton visage
                    </h3>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8 col-xl-7 center">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <div class="card shadow-lg bg-opacity-10 border-0 offre-populaire d-none d-md-block">
                        <div class="bande-jaune"
                            style="background-color: #000000; height: 80px; color: white !important;"></div>
                        <div class="card-body p-4  pt-5 pointer">
                            <div class="d-flex flex-column flex-sm-row mb-1 pt-1">
                                <div>
                                    <h3 *ngIf="showCountdown === true" class="mb-0 fw-bolder"><span class="no-color"
                                            style="background-color: #ffd600; color: #000000;">-80% DE RÉDUCTION
                                            AUJOURD'HUI</span> SUR TES GUIDES FACELESS</h3>
                                    <h3 *ngIf="showCountdown === false" class="mb-0 fw-bolder"><span class="no-color"
                                            style="background-color: #ffd600; color: #000000;">-70% DE RÉDUCTION
                                            AUJOURD'HUI</span> SUR TES GUIDES FACELESS</h3>

                                    <h3 *ngIf="showCountdown === true" class="fw-bolder"><span
                                            class="old-price"><del>97€</del></span>&nbsp;&nbsp;<i
                                            style="font-size: 45px;" class="bi-arrow-right"></i> &nbsp;&nbsp;<span
                                            style="color: #9A42A4;" class="new-price fw-bolder">17.97€</span></h3>

                                    <h3 *ngIf="showCountdown === false" class="fw-bolder"><span
                                            class="old-price fw-bolder"><del>97€</del></span>&nbsp;&nbsp;<i
                                            style="font-size: 45px;" class="bi-arrow-right"></i> &nbsp;&nbsp;<span
                                            style="color: #9A42A4;" class="new-price fw-bolder">27€</span></h3>
                                </div>
                            </div>

                            <h5 *ngIf="showCountdown === true" class="fw-normal mb-3">L'Offre expire dans: </h5>
                            <a *ngIf="showCountdown === true" (click)="scrollToElement('repport')"
                                style="background-color: red; color: white;"
                                class="button button-large button-circle fw-bolder pointer">
                                <i class="bi-clock-fill"></i>
                                {{formatTime(timeLeft) }}
                            </a>                            
                            <a *ngIf="checkoutButton === true" (click)="checkout()"
                                class="button button-large button-circle fw-bolder"
                                style="background-color: #000000; color: #ffffff;">
                                <i class="bi-cloud-download"></i>
                                Crée ta communauté
                            </a>

                            <a *ngIf="checkoutButtonLoading === true"
                                class="button button-large button-circle fw-bolder"
                                style="background-color: #000000; color: #ffffff;">
                                <img src="assets/images/loading.gif" width="20px">
                            </a>

                        </div>
                    </div>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <div class="card shadow-lg bg-opacity-10 border-0 offre-populaire-port d-block d-md-none">
                        <div class="bande-jaune"
                            style="background-color: #000000; height: 60px; color: white !important;"><span
                                style="font-size:25px; font-weight: 900 !important;">Prête à passer à l’action ?</span>
                        </div>
                        <div class="card-body p-4  pt-5 pointer">
                            <div class="d-flex flex-column flex-sm-row mb-1 pt-1">
                                <div>
                                    <h3 *ngIf="showCountdown === true" class="mb-0 fw-bolder"><span class="no-color"
                                            style="background-color: #ffd600; color: #000000;">-80% DE RÉDUCTION
                                            AUJOURD'HUI</span> SUR TES GUIDES FACELESS</h3>
                                    <h3 *ngIf="showCountdown === false" class="mb-0 fw-bolder"><span class="no-color"
                                            style="background-color: #ffd600; color: #000000;">-70% DE RÉDUCTION
                                            AUJOURD'HUI</span> SUR TES GUIDES FACELESS</h3>

                                    <h3 *ngIf="showCountdown === true" class="fw-bolder"><span
                                            class="old-price"><del>97€</del></span>&nbsp;&nbsp;<i
                                            style="font-size: 45px;" class="bi-arrow-right"></i> &nbsp;&nbsp;<span
                                            style="color: #9A42A4;" class="new-price fw-bolder">17.97€</span></h3>

                                    <h3 *ngIf="showCountdown === false" class="fw-bolder"><span
                                            class="old-price fw-bolder"><del>97€</del></span>&nbsp;&nbsp;<i
                                            style="font-size: 45px;" class="bi-arrow-right"></i> &nbsp;&nbsp;<span
                                            style="color: #9A42A4;" class="new-price fw-bolder">27€</span></h3>
                                </div>
                            </div>
                            <h5 *ngIf="showCountdown === true" class="fw-normal mb-3">L'Offre expire dans: </h5>
                            <a *ngIf="showCountdown === true" (click)="scrollToElement('repport')"
                                style="background-color: red; color: white;"
                                class="button button-large button-circle fw-bolder pointer">
                                <i class="bi-clock-fill"></i>
                                {{formatTime(timeLeft) }}
                            </a>

                            <a *ngIf="checkoutButton === true" (click)="checkout()"
                                class="button button-large button-circle fw-bolder"
                                style="background-color: #000000; color: #ffffff;">
                                <i class="bi-cloud-download"></i>
                                Crée ta communauté
                            </a>

                            <a *ngIf="checkoutButtonLoading === true"
                                class="button button-large button-circle fw-bolder"
                                style="background-color: #000000; color: #ffffff;">
                                <img src="assets/images/loading.gif" width="20px">
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section style="margin-top: -80px;">
    <div class="content-wrap">
        <div class="container">
            <div class="heading-block mb-5 text-center border-bottom-0">
                <h2 class="text-transform-none fw-bolder">
                    Ils ont téléchargé les guides <span style="background-color: #9A42A4; color: white;"
                        class="fw-bolder">Instagram</span> et <span
                        style="background-color: #FF004F; color: #ffffff;">Tiktok</span>
                </h2>
            </div>

            <div class="row user-testimonials">
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Grâce à ces guides, j'ai pu construire une audience
                                fidèle sans jamais montrer mon visage. Les stratégies proposées sont adaptées à ceux qui
                                cherchent à rester anonymes tout en ayant un impact fort sur leur audience."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Nina S.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Ces guides m'ont permis de comprendre toutes les
                                astuces pour améliorer mon contenu Instagram et TikTok sans avoir à montrer mon visage.
                                Les conseils sont clairs et faciles à suivre, même pour quelqu'un comme moi qui débute
                                sur les plateformes."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Sarah M.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"J'étais sceptique au début, mais ces guides ont
                                vraiment transformé ma façon de créer du contenu. Les techniques expliquées sont
                                efficaces et m'ont permis de gagner en visibilité tout en restant discrète."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Amélie T.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Les guides sont incroyablement bien structurés.
                                Chaque section m'a apporté des insights précieux pour optimiser mes posts. Je recommande
                                vivement à quiconque souhaite améliorer sa présence sur Instagram et TikTok."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Lucas P.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"J'ai adoré la partie sur l'utilisation des B-Roll et
                                des outils d'IA. Ça m'a ouvert les yeux sur des techniques que je n'aurais jamais
                                imaginées. Mon engagement a considérablement augmenté depuis que j'ai suivi les conseils
                                des guides."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Chloé D.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Ces guides m'ont aidée à mieux comprendre les
                                subtilités d'Instagram et TikTok et à utiliser les bonnes pratiques pour accroître ma
                                visibilité. J'apprécie particulièrement la section sur l'optimisation SEO et les
                                hashtags."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Camille R.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="content">
    <div class="content-wrap py-0">
        <div id="section-faqs" class="page-section my-5">
            <div class="container">
                <div class="row justify-content-between position-relative">
                    <div class="col-lg-5">
                        <h3 class="display-3 ls-n-3 fw-bolder mb-3">
                            <span style="background-color: #9A42A4; color: white;">Questions</span> Fréquemment Posées
                        </h3>
                        <img src="assets/images/ebooks/ebook03/1.jpg" alt="...">
                    </div>
                    <div class="col-lg-6 bg-contrast-0 p-5 rounded-xxl pt-6">
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Comment puis-je créer des Réels et vidéos TikTok engageants sans montrer mon visage
                                    ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Nos guides te montrent comment utiliser des techniques comme les séquences B-Roll, les
                                banques d'images et les outils d'intelligence artificielle pour créer des Réels et
                                vidéos TikTok captivants tout en restant anonyme.
                            </div>
                        </div>

                        <div class="line border-contrast-300 my-4"></div>

                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Quels outils d'IA sont inclus dans les guides ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Les guides recommandent une gamme d'outils pratiques pour t'aider à atteindre tes
                                objectifs. Ils serviront à optimiser des aspects de Réels et vidéos TikTok. Et non, on
                                ne parle pas de Chat GPT 😉
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-il possible de monétiser mes Réels et vidéos TikTok sans montrer mon visage ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Absolument ! Les guides proposent des stratégies pour créer du contenu qui attire et
                                engage ton audience, tout en utilisant des appels à l'action pour monétiser tes Réels et
                                vidéos TikTok.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Ces guides sont-ils adaptés aux débutants ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Oui, ces guides sont conçus pour être accessibles à tous, que tu sois débutant ou déjà
                                expérimenté sur Instagram et TikTok.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Quelle est la durée de la promotion actuelle ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                La promotion actuelle est limitée dans le temps. Assure-toi de profiter de notre offre
                                spéciale avant qu'elle ne se termine !
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Offrez-vous des remboursements ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Étant donné qu'il s'agit de produits numériques, nous n'offrons pas de remboursement
                                après l'achat. Merci de bien vérifier ta commande avant de finaliser l'achat.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Comment puis-je obtenir de l'aide si j'ai des questions supplémentaires ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Tu peux nous contacter directement via notre formulaire de contact ou par email à
                                contact[&#64;]cestnow.com. Nous sommes là pour t'aider !
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</section>

<!-- <a (click)="resetCountdownCache()" class="button btn-small" style="background-color: #9A42A4; color: #ffffff;"> reset </a> -->

<app-simulator-footer></app-simulator-footer>