import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorSession } from "../../session/simulator.session";

@Component({
  selector: 'app-step03',
  templateUrl: './step03.component.html',
  styleUrls: ['./step03.component.css']
})
export class Step03Component {
  
  session : any = {};
  isCardSelected01: boolean = false;
  isCardSelected02: boolean = false;
  isCardSelected03: boolean = false;
  isCardSelected04: boolean = false;


  constructor(private router: Router,private simulatorSession: SimulatorSession) {    }

  ngOnInit(): void {   
    // Get session 
    this.session = this.simulatorSession.getItem('simulator');    
    if(this.session === null || this.session.step2.check === false) {
      this.router.navigateByUrl('simulator/1');         
    }     
  }

  navigateStepFor(response:string) {

    this.session.step3.response = response;
    this.session.step3.check = true;    
    this.simulatorSession.setItem('simulator', this.session);

    if (response === '1') {
      this.isCardSelected01 = true;
      this.isCardSelected02 = false;
      this.isCardSelected03 = false;
      this.isCardSelected04 = false;
    } else if (response === '2') {
      this.isCardSelected01 = false;
      this.isCardSelected02 = true;
      this.isCardSelected03 = false;
      this.isCardSelected04 = false;
    } else if (response === '3') {
      this.isCardSelected01 = false;
      this.isCardSelected02 = false;
      this.isCardSelected03 = true;
      this.isCardSelected04 = false;
    } else if (response === '4') {
      this.isCardSelected01 = false;
      this.isCardSelected02 = false;
      this.isCardSelected03 = false;
      this.isCardSelected04 = true;
    }

    setTimeout(() => {
      this.router.navigateByUrl('simulator/4');
    }, 500); // 500 millisecondes = 0.5 seconde

    
  }

}
