

<section id="content">

    <div class="content-wrap">

        <div class="section text-center bg-white">
            <div class="container py-5">
                <div class="promo-users">
                    <!-- <img src="demos/forum/images/users/1.jpg" alt=".." class="square square-lg rounded-circle">
                    <img src="demos/forum/images/users/2.jpg" alt=".." class="square square-md rounded-circle">
                    <img src="demos/forum/images/users/3.jpg" alt=".." class="square square-md rounded-circle">
                    <img src="demos/forum/images/users/4.jpg" alt=".." class="square square-lg rounded-circle">
                    <img src="demos/forum/images/users/5.jpg" alt=".." class="square square-lg rounded-circle"> -->
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <p class="text-uppercase fw-semibold mb-4 ls-1 font-primary text-black">Get 20% off on first order</p>
                        <h1 class="display-3 fw-normal" style="letter-spacing: -1px;">Start Creating Beautiful Websites Purchase Canvas for <span class="nocolor text-decoration-underline fw-bold" style="text-underline-offset: 5px;">$16</span> only!</h1>
                        <p class="op-07 mw-xs mx-auto mb-5">Canvas is a Powerful, Responsive &amp; Raw Multi-Purpose Multi-Page &amp; One-Page HTML5 Template based on Latest Bootstrap 5 Framework.</p>
                        <a (click)="downloadBusinessFile()" class="button button-xlarge rounded-pill m-0 mt-2 px-5">Télécharger le Rapport</a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>


<app-simulator-footer></app-simulator-footer>