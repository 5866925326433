import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
  })
  export class SimulatorService {
  
    noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  
    constructor(private http: HttpClient) { }
  

    saveReport(data:any) {      
      return this.http.post(environment.apiBaseUrl + '/save/report',data);
    }
   
    checkout(data:any) {            
      return this.http.post(environment.apiBaseUrl + '/checkout',data);
    }
   
    getBusinessFile(data:any) {
      return this.http.get(environment.apiBaseUrl + '/download-report/'+data,{
        responseType: 'blob' as 'json' // Indique que la réponse est un Blob
      });
    }

    getEbookFile(data:any) {
      return this.http.get(environment.apiBaseUrl + '/download-ebook/'+data,{
        responseType: 'blob' as 'json' // Indique que la réponse est un Blob
      });
    }

 
  }


