<section id="content" style="transform: none;">
    <div class="content-wrap py-0" style="transform: none;">
        <div class="section bg-transparent" style="padding: 80px 0">
            <div class="container">
                <div class="heading-block mx-auto border-bottom-0 text-align-justify" style="max-width: 600px">
                    <i class="icon-et-bike d-block h1 mb-4 text-black"></i>                    
                    <h2 class="text-transform-none fw-semibold mb-2" style="font-size: 36px; letter-spacing: -1px"><span
                            class="counter counter-inherit text-dark inline-block">Termes et Conditions </span></h2><br>
                    <small class="text-black-50 text-uppercase ls-3 fw-semibold">1. Mentions légales</small>
                    <p class="lead">Le site www.cestnow.com est édité par la société IMAGROUP LLC Le directeur de la
                        publication du site est cestnow en qualité de gérant.
                        <br>
                        <br>
                        <small class="text-black-50 text-uppercase ls-3 fw-semibold">DÉTAIL DU CONTACT</small>
                        <br>
                        Nom complet de l’entité juridique : IMAGROUP LLC <br>
                        Nom du responsable de la protection des données : cestnow<br>
                        Adresse e-mail : contact@cestnow.com<br>
                        Adresse postale : 1111b South Governors Avenue STE 6967 Dover, DE, 19904 United States
                        <br>
                        <br>
                        <small class="text-black-50 text-uppercase ls-3 fw-semibold">HÉBERGEMENT</small>
                        <br>
                        Le site est hébergé par : DigitalOcean<br>
                        Adresse de l'hébergeur : New York, 101 6th Ave, United States
                                                        
                    </p>
                  
                    <hr>
                    <br>
                    <small class="text-black-50 text-uppercase ls-3 fw-semibold">1.Conditions générales de
                        vente</small><br><br>
                    <small class="text-black fw-bold">Article 1 : Champ d'application</small>
                    <br>
                    <hr>
                    <p>
                        Les présentes conditions générales de vente s'appliquent à toutes les transactions conclues par
                        le biais du site www.cestnow.com Est considéré comme « client » toute personne physique ou
                        morale réalisant auprès de la société IMAGROUP LLC une commande validée via notre
                        plateforme de paiement sécurisée ou virement.
                    </p>
                    <small class="text-black fw-bold">Article 2 : Commande</small>
                    <br>
                    <hr>
                    <p>
                        Toute commande passée sur un site appartenant à IMAGROUP LLC implique l’acceptation intégrale et
                        sans réserve des présentes conditions générales de vente.
                    </p>

                    <small class="text-black fw-bold">Article 3 : Prix</small>
                    <br>
                    <hr>
                    <p>
                        Les prix sont indiqués en euros. Le prix des articles peut être modifié à tout moment.
                        Toutefois, le tarif appliqué à une commande sera celui annoncé au moment de la commande.
                    </p>

                    <small class="text-black fw-bold">Article 4 : Paiement</small>
                    <br>
                    <hr>
                    <p>
                        Le paiement est exigible immédiatement à la date de la commande, y compris pour les produits en
                        précommande. Vous pouvez effectuer le règlement par carte de paiement ou virement bancaire
                        (soumis à condition). Les cartes émises par des banques domiciliées hors de France doivent
                        obligatoirement être des cartes bancaires internationales (Mastercard ou Visa). Le paiement
                        sécurisé en ligne par carte bancaire est réalisé par l’intermédiaire de la société Stripe. Les
                        informations transmises sont cryptées par un logiciel puissant, et ne peuvent être lues au cours
                        du transport sur le réseau. Toute garantie quant à la sécurité de ce système est entièrement de
                        la responsabilité de la banque (ou de cette société) et ne saurait nous être imputée.
                    </p>

                    <small class="text-black fw-bold">Article 5 : Livraison</small>
                    <br>
                    <hr>
                    <p>
                        La livraison est faite à l'adresse électronique que vous avez indiquée lors de votre commande
                        (par conséquent, soyez particulièrement attentif à l'orthographe de l'adresse que vous
                        saisissez).
                    </p>

                    <small class="text-black fw-bold">Article 6 : Conditions de retour et de remboursement</small>
                    <br>
                    <hr>
                    <p>
                        Nos produits étant des produits numériques avec un accès instantané à l'ensemble du produit,
                        aucun remboursement ou retour n'est possible.
                    </p>

                    <small class="text-black fw-bold">Article 6 : Conditions de retour et de remboursement</small>
                    <br>
                    <hr>
                    <p>
                        Nos produits étant des produits numériques avec un accès instantané à l'ensemble du produit,
                        aucun remboursement ou retour n'est possible.
                    </p>

                    <small class="text-black fw-bold">Article 7 : Protection des données personnelles</small>
                    <br>
                    <hr>
                    <p>
                        Conformément à la Réglementation Générale Européenne sur la Protection des Données (RGPD), vous
                        disposez d'un droit d'accès et de rectification aux données personnelles vous concernant. En
                        adhérant à ces conditions générales de vente, vous reconnaissez avoir pris connaissance de notre
                        politique de protection des données personnelles et consentez à ce que nous collections et
                        utilisions ces données.
                    </p>

                    <small class="text-black fw-bold">Article 8 : Droit applicable</small>
                    <br>
                    <hr>
                    <p>
                        Toutes les clauses figurant dans les présentes conditions générales de vente, ainsi que toutes
                        les opérations d'achat et de vente qui y sont visées, seront soumises au droit français.
                    </p>

                    <small class="text-black fw-bold">Article 9 : Informations sur la société IMAGROUP LLC</small>
                    <br>
                    <hr>
                    <p>
                        Toutes les clauses figurant dans les présentes conditions générales de vente, ainsi que toutes
                        les opérations d'achat et de vente qui y sont visées, seront soumises au droit français.
                    </p>

                    <small class="text-black fw-bold">Droits D’auteurs</small>
                    <br>
                    <hr>
                    <p>
                        La totalité des éléments du site www.cestnow.com notamment les textes, présentations,
                        illustrations, photographies, arborescences et mises en forme sont, sauf documents publics et
                        précisions complémentaires, la propriété intellectuelle exclusive de la société IMAGROUP
                        LLC. A ce titre, leurs représentations, reproductions, imbrications, diffusions et rediffusions,
                        partielles ou totales, sont interdites conformément aux dispositions du Code de la propriété
                        intellectuelle. Toute personne y procédant sans pouvoir justifier d'une autorisation préalable
                        et expresse du détenteur de ces droits encourt les peines relatives au délit de contrefaçon
                        prévues au Code de la propriété intellectuelle.
                    </p>

                    <small class="text-black fw-bold">Hébergeurs</small>
                    <br>
                    <hr>
                    <p>
                        Le site est hébergé par : DigitalOcean<br> Adresse de l'hébergeur : New York, 101 6th Ave,
                        United States
                    </p>

                    <!--  -->
                    <small class="text-black-50 text-uppercase ls-3 fw-semibold">2. Politique de
                        confidentialité</small><br><br>
                    <small class="text-black fw-bold">1. INTRODUCTION</small>
                    <br>
                    <hr>
                    <p>
                        Conformément aux dispositions de la RGPD, notre politique de confidentialité vous fournit des
                        détails sur la façon dont nous collectons et traitons vos données personnelles à travers votre
                        utilisation de notre site www.cestnow.com<br>
                        En nous fournissant vos données, vous nous garantissez que vous avez plus de 18 ans.
                        IMAGROUP LLC est le responsable du traitement et nous sommes responsables de vos données
                        personnelles (dénommées "nous" , "Nous" ou "notre" dans cet avis de confidentialité).<br>
                        Nous avons nommé un délégué à la protection des données qui s'occupe des questions liées à la
                        vie privée pour nous. Si vous avez des questions concernant cette politique de confidentialité,
                        veuillez contacter le responsable de la protection des données en utilisant les informations
                        ci-dessous.<br>
                        Détails du contact<br>
                        Nos détails complets sont :<br>
                        Nom complet de l'entité juridique : IMAGROUP LLC<br>
                        Nom du responsable de la protection des données : cestnow<br>
                        Adresse e-mail : contact@cestnow.com<br>
                        Adresse postale : 1111b South Governors Avenue STE 6967 Dover, DE, 19904 United States<br>
                        Il est très important que les informations que nous détenons à votre sujet soient exactes et à
                        jour. Veuillez nous faire savoir si, à tout moment, vos informations personnelles changent en
                        nous envoyant un courriel à contact@cestnow.com
                    </p>

                    <small class="text-black fw-bold">2. QUELLES DONNÉES RECUEILLONS-NOUS SUR VOUS, DANS QUEL BUT ET
                        DANS QUEL CADRE LES TRAITONS-NOUS</small>
                    <br>
                    <hr>
                    <p>
                        Les données personnelles désignent toute information capable d'identifier un individu. Elles
                        n'incluent pas les données anonymisées.
                        Nous pouvons traiter les catégories suivantes de données personnelles vous concernant :
                        <br><br>
                        - Les données de communication qui incluent toute communication que vous nous envoyez, que ce
                        soit
                        via le formulaire de contact sur notre site Web, par e-mail, texte, messagerie sur les réseaux
                        sociaux, publication sur les réseaux sociaux ou toute autre communication que vous nous envoyez.
                        Nous traitons ces données dans le but de communiquer avec vous, pour la tenue de dossiers et
                        pour l'établissement, le suivi ou le traitement de réclamations légales. Notre cadre légal pour
                        ce traitement est notre intérêt légitime qui consiste dans ce cas à répondre aux communications
                        qui nous sont envoyées, à tenir des registres et à établir, poursuivre ou défendre des
                        réclamations légales.
                        <br><br>
                        - Les données du client qui incluent des données relatives à tout achat de biens et / ou de
                        services tels que votre nom, titre, adresse de facturation, adresse e-mail de livraison, numéro
                        de téléphone, coordonnées postales, détails d'achat et détails de votre carte. Nous traitons ces
                        données pour fournir les biens et / ou les services que vous avez achetés et pour conserver les
                        enregistrements de ces transactions. Notre cadre légal pour ce traitement est l'exécution d'un
                        contrat entre vous et nous et / ou prendre des mesures à votre demande pour conclure un tel
                        contrat.
                        <br><br>
                        - Les données utilisateur qui incluent des informations sur la façon dont vous utilisez notre
                        site
                        Web et tous les services en ligne ainsi que les données que vous publiez sur notre site Web ou
                        via d'autres services en ligne. Nous traitons ces données pour exploiter notre site Web et nous
                        assurer que le contenu pertinent vous est fourni, pour assurer la sécurité de notre site Web,
                        maintenir des sauvegardes de notre site Web et / ou des bases de données et permettre la
                        publication et l'administration de notre site Web. Notre cadre légal pour ce traitement est
                        notre intérêt légitime qui, dans ce cas, nous permet de gérer correctement notre site web et
                        notre activité.
                        <br><br>
                        - Les données techniques qui incluent des informations sur votre utilisation de notre site Web
                        et
                        des services en ligne tels que votre adresse IP, vos données de connexion, des détails sur votre
                        navigateur, la durée des visites sur notre site, les pages vues et les chemins de navigation.
                        vous utilisez notre site Web, les paramètres de fuseau horaire et d'autres technologies sur les
                        appareils que vous utilisez pour accéder à notre site Web. La source de ces données provient de
                        notre système de suivi analytique. Nous traitons ces données pour analyser votre utilisation de
                        notre site Web et d'autres services en ligne, pour administrer et protéger notre entreprise et
                        notre site Web, pour vous fournir du contenu et des publicités pertinents et pour comprendre
                        l'efficacité de notre publicité. Notre cadre légal pour ce traitement est notre intérêt légitime
                        qui, dans ce cas, doit nous permettre de gérer correctement notre site web et notre activité et
                        de développer notre activité et de décider de notre stratégie marketing.
                        <br><br>
                        - Les données marketing qui incluent des données sur vos préférences en matière de marketing
                        auprès de nous et de nos tiers et vos préférences de communication. Nous traitons ces données
                        pour vous permettre de participer à nos promotions telles que des concours, des tirages au sort
                        et des cadeaux gratuits, afin de vous fournir du contenu et des publicités pertinents et de
                        mesurer ou comprendre l'efficacité de cette publicité. Notre cadre légal pour ce traitement est
                        nos intérêts légitimes qui dans ce cas sont d'étudier comment les clients utilisent nos produits
                        / services, de les développer, de développer notre activité et de décider de notre stratégie
                        marketing.
                        <br><br>
                        - Nous pouvons utiliser les données du client, les données de l'utilisateur, les données
                        techniques et les données marketing pour vous fournir du contenu et des publicités pertinents (y
                        compris des publicités Facebook ou d'autres publicités) et mesurer ou comprendre l'efficacité de
                        la publicité que nous vous fournissons. Notre cadre légal pour ce traitement est “les intérêts
                        légitimes” qui sont de développer notre entreprise.<br><br>
                        <small class="text-black fw-bold">Données sensibles</small>
                        <br>
                        <br>
                        Nous ne collectons aucune donnée sensible à votre sujet. Les données sensibles font référence à
                        des données sur votre race ou ethnicité, vos croyances religieuses ou philosophiques, votre vie
                        sexuelle, votre orientation sexuelle, vos opinions politiques, votre appartenance syndicale, vos
                        informations sur votre santé et vos données génétiques et biométriques. Nous ne collectons
                        aucune information sur les condamnations pénales et les infractions.
                        <br><br>
                        Nous n'utilisons vos données personnelles que dans un but pour lequel elles ont été collectées
                        ou dans un but raisonnablement compatible si nécessaire. Pour plus d'informations à ce sujet
                        s'il vous plaît écrivez-nous à contact@cestnow.com
                        <br><br>
                        Dans le cas où nous aurions besoin d'utiliser vos coordonnées pour un nouvel objectif sans
                        rapport, nous vous ferons connaître les motifs légaux de traitement.
                        Nous pouvons traiter vos données personnelles à votre insu ou sans votre consentement lorsque
                        cela est requis ou permis par la loi.
                    </p>


                    <small class="text-black fw-bold">3. COMMENT NOUS RECUEILLONS VOS DONNÉES PERSONNELLES</small>
                    <br>
                    <hr>
                    <p>Nous pouvons collecter automatiquement certaines données lorsque vous utilisez notre site Web
                        (par exemple en remplissant des formulaires sur notre site ou en nous envoyant des courriels ou
                        en utilisant des cookies et des technologies similaires).
                        <br><br>
                        Nous pouvons recevoir des données de tiers tels que des fournisseurs d'analyse tels que la
                        section de Google basées en dehors de l'UE, des réseaux de publicité tels que la section de
                        Facebook basés en dehors de l'UE, des fournisseurs de services de recherche, tels que la section
                        de Google basés en dehors de l'UE, tels que les courtiers de données ou les agrégateurs.
                    </p>

                    <small class="text-black fw-bold">4. COMMUNICATIONS MARKETING</small>
                    <br>
                    <hr>
                    <p>Notre motif légal de traiter vos données personnelles pour vous envoyer des communications
                        marketing est soit votre consentement ou nos intérêts légitimes (à savoir pour développer notre
                        activité).
                        En vertu du Règlement sur la vie privée et les communications électroniques, nous pouvons vous
                        envoyer des communications marketing dans l’un des cas suivant :<br><br>
                        • si vous avez effectué un achat ou demandé des informations sur nos produits ou services<br>
                        • si vous avez accepté de recevoir des communications marketing et que vous n'avez pas refusé de
                        recevoir de telles communications depuis.<br><br>
                        En vertu de ces règlements, si vous êtes une société à responsabilité limitée, nous pouvons vous
                        envoyer des courriels de marketing sans votre consentement. Cependant, vous pouvez toujours
                        refuser de recevoir des courriels de marketing de notre part à tout moment.
                        Avant de partager vos données personnelles avec un tiers à des fins de marketing, nous
                        obtiendrons votre consentement exprès.
                        Vous pouvez demander à nous ou à des tiers d'arrêter de vous envoyer des messages marketing à
                        tout moment en en cliquant sur les liens de désabonnement (présents sur tout message marketing
                        envoyé) ou simplement en nous envoyant un courriel à contact@cestnow.com à tout moment.
                        Si vous choisissez de ne plus recevoir de communications marketing, cette désactivation ne
                        s'applique pas aux données personnelles fournies à la suite d'autres transactions, telles que
                        les achats, les enregistrements de garantie, etc
                    </p>

                    <small class="text-black fw-bold">5. DIVULGATION DE VOS DONNÉES PERSONNELLES</small>
                    <br>
                    <hr>
                    <p>Nous pouvons être amenés à partager vos données personnelles avec les parties mentionnées
                        ci-dessous:<br>
                        • ​Fournisseurs de services qui fournissent des services informatiques et d'administration
                        système.<br>
                        • ​Les conseillers professionnels, y compris les avocats, les banquiers, les auditeurs et les
                        assureurs<br>
                        • ​Les organismes gouvernementaux qui nous obligent à signaler les activités de traitement.<br>
                        •​ Les tiers auxquels nous vendons, transférons ou fusionnons des parties de nos activités ou de
                        nos actifs. (Nous demandons à tous les tiers auxquels nous transférons vos données de respecter
                        la sécurité de vos données personnelles et de les traiter conformément à la loi. Nous autorisons
                        uniquement ces tiers à traiter vos données personnelles à des fins spécifiques et conformément à
                        nos instructions.)
                    </p>

                    <small class="text-black fw-bold">6. TRANSFERTS INTERNATIONAUX</small>
                    <br>
                    <hr>
                    <p>Nous sommes soumis aux dispositions de la réglementation générale sur la protection des données
                        qui protègent vos données personnelles. Lorsque nous transférons vos données à des tiers en
                        dehors de l’UE, nous veillons à ce que certaines mesures de protection soient en place pour
                        assurer un degré de sécurité similaire pour vos données personnelles.<br>
                        En tant que tel :<br>
                        • ​Nous pouvons transférer vos données personnelles vers des pays que la Commission européenne a
                        approuvés comme fournissant un niveau adéquat de protection des données personnelles.<br>
                        • ​Si nous utilisons des fournisseurs basés aux États-Unis qui font partie de Privacy Shield
                        UE-États-Unis, nous pouvons leur transférer des données, car ils ont des garanties équivalentes
                        en place.<br>
                        • ​Lorsque nous utilisons certains prestataires de services établis en dehors de l'EEE, nous
                        pouvons utiliser des contrats spécifiques ou des codes de conduite ou des mécanismes de
                        certification approuvés par la Commission européenne qui confèrent aux données personnelles la
                        même protection qu'en Europe.<br>
                        • Si aucune des garanties ci-dessus n'est disponible, nous pouvons demander votre consentement
                        explicite au transfert spécifique. Vous aurez le droit de retirer ce consentement à tout moment.
                    </p>

                    <small class="text-black fw-bold">7. SÉCURITÉ DES DONNÉES</small>
                    <br>
                    <hr>
                    <p>Nous avons mis en place des mesures de sécurité pour empêcher que vos données personnelles soient
                        accidentellement perdues, utilisées, modifiées, divulguées ou consultées sans autorisation. Nous
                        autorisons également l'accès à vos données personnelles uniquement aux employés et partenaires
                        qui ont besoin de connaître ces données. Ils ne traiteront vos données personnelles que sur nos
                        instructions et devront les garder confidentielles.
                    </p>

                    <small class="text-black fw-bold">8. RÉTENTION DES DONNÉES</small>
                    <br>
                    <hr>
                    <p>Nous ne conserverons vos données personnelles que le temps nécessaire pour remplir les objectifs
                        pour lesquels nous les avons collectées, y compris pour exigences légales, comptables ou de
                        reporting.<br>
                        Lorsque nous décidons du moment opportun pour conserver les données, nous examinons leur nature
                        et leur sensibilité, le risque potentiel de préjudice résultant d'une utilisation ou d'une
                        divulgation non autorisée, le traitement et si ces moyens peuvent être atteints par d'autres
                        moyens.<br>
                        Pour des raisons fiscales, la loi nous oblige à conserver des informations de base sur nos
                        clients (y compris les données de contact, d'identité, financières et de transaction) pendant
                        six ans après qu'ils cessent d'être des clients.<br>
                        Dans certaines circonstances, nous pouvons anonymiser vos données personnelles à des fins de
                        recherche ou statistiques, auquel cas nous pouvons utiliser ces informations indéfiniment sans
                        autre avis.
                    </p>

                    <small class="text-black fw-bold">9. VOS DROITS LÉGAUX</small>
                    <br>
                    <hr>
                    <p>Conformément aux lois sur la protection des données, vous avez le droit de demander l'accès, la
                        correction, l'effacement, la restriction, le transfert, le refus de traitement, la
                        transférabilité des données et (lorsque le motif légitime du traitement est le consentement) de
                        retirer votre consentement.<br>
                        Vous pouvez en savoir plus sur ces droits sur:
                        https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3
                        Si vous souhaitez exercer l'un des droits énoncés ci-dessus, veuillez nous envoyer un courriel à
                        contact@cestnow.com<br>
                        Vous n'aurez pas à payer de frais pour accéder à vos données personnelles (ou pour exercer l'un
                        des autres droits). Cependant, nous pouvons exiger des frais raisonnables si votre demande est
                        clairement non fondée, répétitive ou excessive ou si vous refusez de vous conformer à votre
                        demande dans ces circonstances.<br>
                        Nous pouvons avoir besoin de vous demander des informations spécifiques pour nous aider à
                        confirmer votre identité et garantir votre droit d'accéder à vos données personnelles (ou
                        d'exercer l'un de vos autres droits). Il s'agit d'une mesure de sécurité visant à garantir que
                        les données personnelles ne sont pas divulguées à une personne qui n'a pas le droit de les
                        recevoir. Nous pouvons également vous contacter pour vous demander de plus amples informations
                        concernant votre demande afin d'accélérer notre réponse.<br>
                        Nous essayons de répondre à toutes les demandes légitimes dans un délai d'un mois. Parfois, cela
                        peut prendre plus d'un mois si votre demande est particulièrement complexe ou si vous avez fait
                        un certain nombre de demandes. Dans ce cas, nous vous en informerons.<br>
                        Si vous n'êtes pas satisfait de la manière dont nous collectons et utilisons vos données, vous
                        avez le droit de vous adresser à la CNIL, Commission Nationale de l'Informatique et des Libertés
                        (cnil.fr). Nous vous serions reconnaissants de bien vouloir nous contacter d'abord si vous avez
                        une réclamation afin que nous puissions essayer de la résoudre pour vous.
                    </p>

                    <small class="text-black fw-bold">10. LIENS DE TIERS</small>
                    <br>
                    <hr>
                    <p>Ce site Web peut inclure des liens vers des sites Web, des plug-ins et des applications de tiers.
                        En cliquant sur ces liens ou en activant ces connexions, il est possible que des tiers puissent
                        collecter ou partager des données vous concernant. Nous ne contrôlons pas ces sites Web tiers et
                        ne sommes pas responsables de leurs politiques de confidentialité.
                        Lorsque vous quittez notre site Web, nous vous encourageons à lire la politique de
                        confidentialité de chaque site Web que vous visitez.
                    </p>

                    <small class="text-black fw-bold">11. COOKIES</small>
                    <br>
                    <hr>
                    <p>Vous pouvez configurer votre navigateur pour qu'il refuse tout ou partie des cookies du
                        navigateur. Si vous désactivez ou refusez les cookies, veuillez noter que certaines parties de
                        ce site peuvent devenir inaccessibles ou ne pas fonctionner correctement. Pour plus
                        d'informations sur les cookies que nous utilisons, veuillez consulter notre politique de cookies
                        <br><br>
                        <small class="text-black fw-bold">NOTRE POLITIQUE DE COOKIES</small><br><br>
                        Le Site utilise des cookies. Lors de la consultation du Site, des cookies sont déposés sur votre
                        ordinateur, votre tablette ou votre smartphone. Les informations ci-dessous visent à permettre à
                        l’Utilisateur de mieux comprendre comment fonctionnent les cookies et où trouver les outils
                        permettant de les paramétrer.
                        <br><br>
                        <small class="text-black fw-bold">QU'EST-CE QU'UN COOKIE ?</small><br><br>
                        L’utilisation des cookies est courante sur Internet. Un cookie est un petit fichier texte
                        contenant des informations, qui est enregistré sur le disque dur de votre terminal (ex :
                        ordinateur, tablette ou téléphone mobile) à l’occasion de la consultation d’un site Internet
                        grâce à votre logiciel de navigation. Il est transmis par le serveur d’un site Internet à votre
                        navigateur. Le fichier cookie permet à son émetteur d’identifier le terminal dans lequel il est
                        enregistré pendant la durée de validité ou d’enregistrement du cookie concerné. Un cookie ne
                        permet pas de remonter à une personne physique.
                        <br><br>
                        <small class="text-black fw-bold">POURQUOI L'UTILISATION DES COOKIES SUR LE SITE
                            ?</small><br><br>
                        Le Site utilise 3 catégories de cookies dont les finalités sont décrites ci-après. Aucun cookie
                        n’est utilisé pour collecter des informations nominatives (comme le nom de l’Utilisateur par
                        exemple).
                        <br><br>
                        • 1. Les cookies de fonctionnalités
                        Ces cookies ne sont pas indispensables à la navigation sur le Site mais permettent d’optimiser
                        le fonctionnement du Site et de donner proposer à une navigation fluide et sur mesure.
                        En l’occurrence l’Editeur utilise un seul cookie de ce type. Il s’agit du cookie de session. Sa
                        durée de vie est égale au temps de la session de l’Utilisateur. Ce temps est limité à 1 heure en
                        cas d’inactivité et au maximum, à 24 heures.
                        <br><br>
                        • 2. Les cookies de mesure d’audience (statistiques)
                        Ces cookies recueillent des informations sur la façon dont les visiteurs utilisent un site Web.
                        Par exemple, le nombre de visites, les pages les plus visitées…
                        Google Analitycs, l’outil de mesure d’audience et de statistiques utilisé par le Site, génère un
                        cookie avec un identifiant unique, dont la durée de conservation est limitée à 13 mois. Google
                        Analitycs collecte l’adresse IP, afin d’identifier la ville depuis laquelle l’Utilisateur se
                        connecte. Celle-ci est immédiatement anonymisée après utilisation. L’Editeur ne peut donc en
                        aucun cas remonter par ce biais à une personne physique.
                        Les données statistiques obtenues ne sont pas cédées à des tiers ni utilisées à d’autres fins.
                        <br><br>
                        • 3. Les cookies de partage (liens sociaux)
                        Le Site contient des liens de partage vers Facebook, Twitter et autres réseaux sociaux
                        similaires, qui vous permettent de partager des contenus du Site avec d’autres personnes.
                        Lorsque l’Utilisateur utilise ces boutons de partage, un cookie tiers est installé. Si
                        l’Utilisateur est connecté au réseau social lors de sa navigation sur le Site, les boutons de
                        partage permettent de relier les contenus consultés au compte utilisateur.
                        <br><br>
                        • 4. Les cookies publicitaires
                        Ces cookies collectent des informations concernant vos habitudes en matière de navigation
                        Internet afin de vous proposer des publicités qui vous sont adaptées et correspondent à vos
                        centres d’intérêt. Ils permettent également de limiter le nombre de fois que vous verrez une
                        publicité et nous aident à évaluer l’efficacité de nos campagnes publicitaires. Ils sont en
                        général installés par des réseaux publicitaires avec l’autorisation de l’opérateur du site
                        Internet. Ils mémorisent les visites effectuées sur un site et partagent ces informations avec
                        d’autres entreprises (des publicitaires par exemple). Les cookies ciblés ou publicitaires sont
                        souvent liés aux fonctionnalités du site fournies par l’entreprise partenaire.
                        Les informations sont collectées et partagées avec des tiers pour proposer des ciblages
                        d’audience et des solutions d’optimisation pour les annonceurs publicitaires et les éditeurs.
                        Aucune donnée personnelle n’est recueillie et un individu ne peut être identifié via les données
                        collectées.
                        <br><br>
                        • Comment gérer les cookies ?
                        Plusieurs possibilités sont offertes à l’Utilisateur pour gérer les cookies. Toutefois, il est
                        précisé que tout paramétrage réalisé par l’Utilisateur est susceptible de modifier la navigation
                        sur Internet et les conditions d’accès à certains services nécessitant l’utilisation de cookies.
                        L’Utilisateur peut faire le choix à tout moment d’exprimer et de modifier ses souhaits en
                        matière de cookies, par les moyens décrits ci-dessous.
                        
                        <br><br><br>

                        Plus d’information sur les cookies
                        Pour plus d’information sur les cookies, l’Utilisateur peut se rendre sur le site de la CNIL, à
                        cette adresse : http://www.cnil.fr/vos-droits/vos-traces/les-cookies/
                    </p>

                </div>

            </div>
        </div>
    </div>
</section>


<app-simulator-footer></app-simulator-footer>