<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">                    
                    <div class="col-lg-12">
                        <div class="row g-4 align-items-center justify-content-center">
                            <div id="tab-reservation" class="nav nav-pills flex-column flex-lg-row" role="tablist">
                                <a class="nav-link active ms-0 fw-bolder" routerLink="/simulator/6" ><i class="bi-arrow-left-circle-fill"></i></a>                                                                                                        
                                <a class="nav-link active ms-0 mt-2 mt-lg-0 ms-lg-3  fw-bolder" routerLink="/simulator/7">7. Site internet</a>                            
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    
    <section id="content" class="pt-5">
        <div class="content-wrap py-10">
            <div class="border-bottom py-4">
                <div class="container">              
                    <div class="text-center mb-5">
                        <h3 class="display-4 fw-bolder ls1 mb-3 position-relative">7 / As-tu un site internet pour ton <span class="gradient-text gradient-color">Business</span> ?</h3>
                        <!-- <h3 class="display-4 fw-bolder ls1 mb-3 position-relative">7 /As-tu une boutique en ligne pour ton <span class="gradient-text gradient-color">Business</span> ?</h3> -->
                        <p class="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,</p>
                        <!-- <a href="#" class="button button-border button-large rounded-pill m-0">Etape 8 - Type de business ?</a> -->
                    </div>
                    <div class="row align-items-stretch g-4 center mb-5">
                        <div class="col-md-6 text-center">
                            <div class="card bg-secondary bg-opacity-10 border-0">
                                <div class="card-body p-4 pointer" [class.selected-border]="session.step7.response === '1'"
                                [ngStyle]="{'background-color': isCardSelected01 ? '#ffd600' : '#F0F1F2'}"
                                (click)="navigateStepEight('1')">
                                    <h4 class="mb-3 fw-bolder tile-question">Oui</h4>
                                    <p class="mb-3 text-muted">Lorem ipsum dolor sit amet, consectetur</p>
                                    <!-- <a (click)="selectOnlineSolution()" class="button button-small button-border h-bg-dark rounded fw-bolder">Validé</a> -->
                                    <a class="button button-large button-circle fw-bolder" style="background-color: #FFD600; color: black;"><i  *ngIf="session.step7.response === '1'" class="fa-solid fa-check"></i>Sélectionner</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 text-center">
                            <div class="card bg-secondary bg-opacity-10 border-0">
                                <div class="card-body p-4 pointer" [class.selected-border]="session.step7.response === '2'"
                                [ngStyle]="{'background-color': isCardSelected02 ? '#ffd600' : '#F0F1F2'}"
                                (click)="navigateStepEight('2')">
                                    <h4 class="mb-3 fw-bolder tile-question">Non</h4>
                                    <p class="mb-3 text-muted">Globally morph bleeding-edge internal.</p>
                                    <!-- <a (click)="navigateStepEight()" class="button button-small button-border h-bg-dark rounded fw-bolder">Validé</a> -->
                                    <a class="button button-large button-circle fw-bolder" style="background-color: #FFD600; color: black;"><i *ngIf="session.step7.response === '2'"  class="fa-solid fa-check"></i>Sélectionner</a>
                                </div>
                            </div>
                        </div>
                    </div>        
                    <!-- <div class="row align-items-stretch g-4 text-center mb-5">                   
                        <div class="col-md-12 center-both">                                                   
                              <div class="card bg-secondary bg-opacity-10 border-0">
                                <div class="card-body p-4">
                                    <h4 class="mb-5 pt-3 fw-bolder">Saisie l'url de ton site internet</h4>
                                    <h4 class="mb-5 pt-3 fw-bolder">Saisie l'url de ta boutique</h4>
                                    <div class="input-group form-group ">
                                        <span class="input-group-text fw-bolder" id="basic-addon3">www.</span>
                                        <input  type="text" class="form-control col-6" id="basic-social-fb" aria-describedby="basic-addon3 basic-addon4">                            
                                    </div>
                                      <br>                                    
                                    <a (click)="navigateStepEight('2')" class="button button-large button-circle fw-bolder" style="background-color: #FFD600; color: black;"><i class="fa-solid fa-check"></i>Sélectionner</a>
                                </div>
                            </div>
                        </div>
                    </div>  -->

                    <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="width: 87.5%;">                      
                        </div>
                    </div> 
                    
                    <!-- <div class="row align-items-center gy-3">                    
                        <div class="col-lg-12">
                            <div class="skill-progress mb-4" data-percent="90" data-speed="2000" style="--cnvs-progress-height: 36px; --cnvs-progress-rounded: 50rem;">
                                <div class="skill-progress-bar">
                                    <div class="skill-progress-percent gradient-blue"></div>
                                    <div class="d-flex position-absolute w-100 h-100 px-3 dark justify-content-between align-items-center">
                                        <h5 class="mb-0">Etape 07</h5>
                                        <div class="text-dark">
                                            <small class="fw-semibold"><div class="counter counter-inherit counter-instant"></div></small>
                                        </div>
                                    </div>
                                </div>
                            </div>                                    
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
        
<app-simulator-footer></app-simulator-footer>