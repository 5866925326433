<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-lg-12">
                        <div class="row g-4 align-items-center justify-content-center">
                            <div id="tab-reservation" class="nav nav-pills flex-column flex-lg-row" role="tablist">
                                <a class="nav-link active ms-0 fw-bolder" routerLink="/simulator/8"><i
                                        class="bi-arrow-left-circle-fill"></i></a>
                                <a class="nav-link active ms-0 mt-2 mt-lg-0 ms-lg-3  fw-bolder"
                                    routerLink="/simulator/email">Adresse Email</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="content" class="pt-5">
    <div class="content-wrap py-10">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative"> Votre rapport <span
                            class="gradient-text gradient-color">Business</span> </h3>                    
                    <p class="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                        doloremque laudantium,</p>                    
                </div>

                <div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
                    <div class="alert alert-danger" role="alert">
                        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
                    </div>
                </div>
          
                <div class="row align-items-stretch g-4 text-center mb-5 justify-content-center">
                    <div class="col-md-6 mx-auto">
                      <div class="card bg-secondary bg-opacity-10 border-0">
                        <div class="card-body p-4" [class.selected-border]="session.stepEmail.response === '1'"
                        [ngStyle]="{'background-color': isCardSelected ? '#ffd600' : '#F0F1F2'}">
                            <h4 class="mb-3 pt-3 fw-bolder">Votre adresse e-mail</h4>
                            <!-- <input type="email" [(ngModel)]="email" (ngModelChange)="validateEmail()" [ngClass]="{'is-invalid': emailInvalid, 'is-empty': emailEmpty}" class="form-control" id="emailInput" placeholder="Entrez votre email"> -->
                            <input type="email" [(ngModel)]="email" (ngModelChange)="validateEmail()" [ngClass]="{'is-invalid': emailInvalid, 'is-empty': emailEmpty, 'custom-input': true}" class="form-control" id="emailInput" placeholder="Entrez votre email">
                            <!-- <input [(ngModel)]="email" type="text" class="form-control col-6" id="basic-social-fb" aria-describedby="basic-addon3 basic-addon4"> -->
                            <br>
                            <a (click)="generateReport()" class="button button-large button-circle fw-bolder" style="background-color: #FFD600; color: black;"><i class="fa-solid fa-check"></i>Obtenez le rapport</a>
                        </div>
                      </div>
                    </div>                                      
                  </div>
                       
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                        aria-valuemax="100" style="width: 87.5%;">
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>