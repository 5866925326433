<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>

<!-- home Version big sreenn -->
<section class="big-screen-section">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">S’aimer</div>
                    <h2 class="display-5 mb-4 fw-bold lh-sm" style="font-family: 'Manrope', sans-serif;">Comment élever
                        l'amour-propre et guérir l'esprit, le corps et l'âme</h2>
                    <p class="lead mb-5">93 pages de contenu prêt à l'emploi !
                        Es-tu prêt(e) à te lancer dans un voyage profond de découverte de soi, de guérison et d'amour
                        propre ? Notre eBook et Workbook, "Déchaînement de l'Amour Propre", te guideront à travers un
                        processus enrichissant pour libérer ton véritable potentiel et embrasser l'amour que tu mérites.
                    </p>
                    <p>
                        <span class="old-price fw-bold" style="font-size: 24px;">17,00 €</span>&nbsp;&nbsp;&nbsp;
                        <span class="fw-bold" style="font-size: 24px;">7.97 €</span>
                    </p>
                    
                    <a style="background-color: #c7ac97;" (click)="checkout()" *ngIf="checkoutButton === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold">Acheter
                        <i class="bi-arrow-right"></i></a>
                    <a style="background-color: #c7ac97;" *ngIf="checkoutButtonLoading === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold"><img
                            src="assets/images/loading.gif" width="20px"> </a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook01/2.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook01/3.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook01/4.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--home Version smaal sreenn -->
<section class="small-screen-section">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">S’aimer</div>
                    <h2 class="display-5 mb-4 fw-bold lh-sm" style="font-family: 'Manrope', sans-serif;">Comment élever
                        l'amour-propre et guérir l'esprit, le corps et l'âme</h2>
                    <p class="lead mb-5">93 pages de contenu prêt à l'emploi !
                        Es-tu prêt(e) à te lancer dans un voyage profond de découverte de soi, de guérison et d'amour
                        propre ? Notre eBook et Workbook, "Déchaînement de l'Amour Propre", te guideront à travers un
                        processus enrichissant pour libérer ton véritable potentiel et embrasser l'amour que tu mérites.
                    </p>
                    <p>
                        <span class="old-price fw-bold" style="font-size: 24px;">17,00 €</span>&nbsp;&nbsp;&nbsp;
                        <span class="fw-bold" style="font-size: 24px;">7.97 €</span>
                    </p>
                    
                    <a style="background-color: #c7ac97;" (click)="checkout()" *ngIf="checkoutButton === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold">Acheter
                        <i class="bi-arrow-right"></i></a>
                    <a style="background-color: #c7ac97;" *ngIf="checkoutButtonLoading === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold"><img
                            src="assets/images/loading.gif" width="20px"> </a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook01/2.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook01/3.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook01/4.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- home Version tablette -->
<section class="tablet-section">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-12">
                    <div class="text-uppercase ls-2 mb-4 op-04">S’aimer</div>
                    <h2 class="display-5 mb-4 fw-bold lh-sm" style="font-family: 'Manrope', sans-serif;">Comment élever
                        l'amour-propre et guérir l'esprit, le corps et l'âme</h2>
                    <p class="lead mb-5">93 pages de contenu prêt à l'emploi !
                        Es-tu prêt(e) à te lancer dans un voyage profond de découverte de soi, de guérison et d'amour
                        propre ? Notre eBook et Workbook, "Déchaînement de l'Amour Propre", te guideront à travers un
                        processus enrichissant pour libérer ton véritable potentiel et embrasser l'amour que tu mérites.
                    </p>
                    <p>
                        <span class="old-price fw-bold" style="font-size: 24px;">17,00 €</span>&nbsp;&nbsp;&nbsp;
                        <span class="fw-bold" style="font-size: 24px;">7.97 €</span>
                    </p>
                    
                    <a style="background-color: #c7ac97;" (click)="checkout()" *ngIf="checkoutButton === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold">Acheter
                        <i class="bi-arrow-right"></i></a>
                    <a style="background-color: #c7ac97;" *ngIf="checkoutButtonLoading === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold"><img
                            src="assets/images/loading.gif" width="20px"> </a>
                </div>
                
            </div>
        </div>
    </div>
</section>

<!-- home Version mobil -->
<section class="mobile-section">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-12">
                    <div class="text-uppercase ls-2 mb-4 op-04">S’aimer</div>
                    <h2 class="display-5 mb-4 fw-bold lh-sm" style="font-family: 'Manrope', sans-serif;">Comment élever
                        l'amour-propre et guérir l'esprit, le corps et l'âme</h2>
                    <p class="lead mb-5">93 pages de contenu prêt à l'emploi !
                        Es-tu prêt(e) à te lancer dans un voyage profond de découverte de soi, de guérison et d'amour
                        propre ? Notre eBook et Workbook, "Déchaînement de l'Amour Propre", te guideront à travers un
                        processus enrichissant pour libérer ton véritable potentiel et embrasser l'amour que tu mérites.
                    </p>
                    <p>
                        <span class="old-price fw-bold" style="font-size: 24px;">17,00 €</span>&nbsp;&nbsp;&nbsp;
                        <span class="fw-bold" style="font-size: 24px;">7.97 €</span>
                    </p>
                    
                    <a style="background-color: #c7ac97;" (click)="checkout()" *ngIf="checkoutButton === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold">Acheter
                        <i class="bi-arrow-right"></i></a>
                    <a style="background-color: #c7ac97;" *ngIf="checkoutButtonLoading === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold"><img
                            src="assets/images/loading.gif" width="20px"> </a>
                </div>            
            </div>
        </div>
    </div>
</section>



<!-- Version big sreenn -->
<section class="big-screen-section">
    <div class="content-wrap">
        <div class="container">

            <div class="row gutter-50">
                
                <div class="col-lg-7 portfolio-single-image">
                

                    <div class="row col-mb-30 ">
                        <a class="col-lg-12 col-md-6 " ><img src="assets/images/ebooks/ebook01/1.jpg" class="rounded-5" alt="Image 1"></a>                                                
                        <a class="col-lg-12 col-md-6 " ><img src="assets/images/ebooks/ebook01/2.jpg" class="rounded-5" alt="Image 1"></a>                                                
                        <a class="col-lg-12 col-md-6 " ><img src="assets/images/ebooks/ebook01/3.jpg" class="rounded-5" alt="Image 1"></a>                                                
                        <a class="col-lg-12 col-md-6 " ><img src="assets/images/ebooks/ebook01/4.jpg" class="rounded-5" alt="Image 1"></a>                                                
                    </div>                    
                </div>
               
                <div class="col-lg-5 portfolio-single-content content-sticky">
                                   
                    <h2>93 pages de contenu prêt à l'emploi !</h2>
                    <p class="op-07">Es-tu prêt(e) à te lancer dans un voyage profond de découverte de soi, de guérison
                        et d'amour propre ? Notre eBook et Workbook, "Déchaînement de l'Amour Propre", te guideront à
                        travers un processus enrichissant pour libérer ton véritable potentiel et embrasser l'amour que
                        tu mérites.
                        <br></p>
                    <h5>Ce que tu trouveras à l'intérieur : </h5>
                    <p class="op-07">
                        Découvre le pouvoir transformateur de l'amour propre avec notre pack eBook et Workbook complet,
                        conçu pour t'aider à guérir ton esprit, ton corps et ton âme. Élève ton estime de soi, améliore
                        ton bien-être et acquiers les outils pour vivre une vie plus épanouissante.
                    </p>

                    <h5>Trouve ton vrai moi (Chapitre 1) : </h5>
                    <p class="op-07">
                        Gagne des insights sur la découverte de soi et apprends à te connecter avec ton moi authentique,
                        jetant les bases d'une vie pleine de sens et de bonheur.                        
                    </p>

                    <h5>Affronter tes ombres (Chapitre 2) </h5>
                    <p class="op-07">
                        Plonge profondément dans l'amour de soi en comprenant et en adressant tes peurs et doutes
                        intérieurs, te permettant de libérer les bagages émotionnels qui t'ont freinée.
                        Construire une solide fondation d'amour propre
                    </p>

                    <h5>Construire une solide fondation d'amour propre (Chapitre 3)</h5>
                    <p class="op-07">
                        Découvre des stratégies pratiques et des exercices pour cultiver l'amour propre, boostant ton
                        estime de soi et favorisant un profond sentiment de valeur personnelle.
                    </p>

                    <h5>Stratégies alternatives pour l'amour propre (Chapitre 4)</h5>
                    <p class="op-07">
                        Explore une large gamme de techniques d'amour propre, te permettant de choisir ce qui résonne le plus avec toi, créant un chemin personnalisé vers l'acceptation de soi.
                    </p>
                  
                    <h5>Psychologie de l'estime de soi (Chapitre 5)</h5>
                    <p class="op-07">
                        Comprends les complexités de l'estime de soi et la science qui se cache derrière, t'offrant une perspective plus profonde sur ton voyage vers l'amour de soi.
                    </p>
                  
                    <h5>Maîtriser tes pensées et émotions (Chapitre 6)</h5>
                    <p class="op-07">
                        Apprends à naviguer adroitement tes pensées et émotions, prenant le contrôle sur la négativité et cultivant un état d'esprit positif.
                    </p>
                
                    <h5>Boosters créatifs d'amour propre (Chapitre 7) </h5>
                    <p class="op-07">
                        Découvre des méthodes uniques et agréables pour intégrer l'amour propre dans ta vie quotidienne, assurant que ton voyage soit à la fois enrichissant et amusant.
                    </p>

                    <div class="line my-5"></div>
                    
                    <div class="row g-4 mt-4 mb-6">
                        <div class="col-12">
                            <h5 class="mb-2">Feuilles de travail du Workbook </h5>
                            <h5 class="mb-2">Réflexions hebdomadaires et quotidiennes </h5>
                            <h5 class="mb-2">Défi de 28 jours "Aime-toi" </h5>
                            <h5 class="mb-2">Journal de l'amour propre et journal de pleine conscience</h5>
                            <h5 class="mb-2">Suivi du sommeil et de l'humeur</h5>
                            <h5 class="mb-2">Planificateur de soins personnels et routine</h5>
                            <h5 class="mb-2">Journal de l'eau</h5>
                            <h5 class="mb-2">Liste "Défaire l'indéfait"</h5>
                            <h5 class="mb-2">Vidage cérébral</h5>
                            <h5 class="mb-2">Journal de l'eau</h5>                            
                        </div>                        
                    </div>
                    

                    <div class="line my-5"></div>

                    <p>
                        <span class="old-price fw-bold" style="font-size: 24px;">17,00 €</span>&nbsp;&nbsp;&nbsp;
                        <span class="fw-bold" style="font-size: 24px;">7.97 €</span>
                    </p>

                    <div class="d-flex justify-content-between align-items-center">
                        <a style="background-color: #c7ac97;" (click)="checkout()" *ngIf="checkoutButton === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold">Acheter
                        <i class="bi-arrow-right"></i></a>
                    <a style="background-color: #c7ac97;" *ngIf="checkoutButtonLoading === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold"><img
                            src="assets/images/loading.gif" width="20px"> </a>
                
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</section>

<!-- Version smaal sreenn -->
<section class="small-screen-section">
    <div class="content-wrap">
        <div class="container">

            <div class="row gutter-50">                
                <div class="col-lg-7 portfolio-single-image">                

                    <div class="masonry-thumbs grid-container row row-cols-2 masonry-gap-xl mb-2" data-lightbox="gallery">
                        <a  class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/1.jpg" alt="Image">
                        </a>
                        <a  class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/2.jpg" alt="Image">
                        </a>
                        <a  class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/3.jpg" alt="Image">
                        </a>
                        <a  class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/4.jpg" alt="Image">
                        </a>
                    </div>                    
                </div>

                
                <div class="col-lg-5 portfolio-single-content content-sticky">
                                   
                    <h2>93 pages de contenu prêt à l'emploi !</h2>
                    <p class="op-07">Es-tu prêt(e) à te lancer dans un voyage profond de découverte de soi, de guérison
                        et d'amour propre ? Notre eBook et Workbook, "Déchaînement de l'Amour Propre", te guideront à
                        travers un processus enrichissant pour libérer ton véritable potentiel et embrasser l'amour que
                        tu mérites.
                        <br></p>
                    <h5>Ce que tu trouveras à l'intérieur : </h5>
                    <p class="op-07">
                        Découvre le pouvoir transformateur de l'amour propre avec notre pack eBook et Workbook complet,
                        conçu pour t'aider à guérir ton esprit, ton corps et ton âme. Élève ton estime de soi, améliore
                        ton bien-être et acquiers les outils pour vivre une vie plus épanouissante.
                    </p>

                    <h5>Trouve ton vrai moi (Chapitre 1) : </h5>
                    <p class="op-07">
                        Gagne des insights sur la découverte de soi et apprends à te connecter avec ton moi authentique,
                        jetant les bases d'une vie pleine de sens et de bonheur.                        
                    </p>

                    <h5>Affronter tes ombres (Chapitre 2) </h5>
                    <p class="op-07">
                        Plonge profondément dans l'amour de soi en comprenant et en adressant tes peurs et doutes
                        intérieurs, te permettant de libérer les bagages émotionnels qui t'ont freinée.
                        Construire une solide fondation d'amour propre
                    </p>

                    <h5>Construire une solide fondation d'amour propre (Chapitre 3)</h5>
                    <p class="op-07">
                        Découvre des stratégies pratiques et des exercices pour cultiver l'amour propre, boostant ton
                        estime de soi et favorisant un profond sentiment de valeur personnelle.
                    </p>

                    <h5>Stratégies alternatives pour l'amour propre (Chapitre 4)</h5>
                    <p class="op-07">
                        Explore une large gamme de techniques d'amour propre, te permettant de choisir ce qui résonne le plus avec toi, créant un chemin personnalisé vers l'acceptation de soi.
                    </p>
                  
                    <h5>Psychologie de l'estime de soi (Chapitre 5)</h5>
                    <p class="op-07">
                        Comprends les complexités de l'estime de soi et la science qui se cache derrière, t'offrant une perspective plus profonde sur ton voyage vers l'amour de soi.
                    </p>
                  
                    <h5>Maîtriser tes pensées et émotions (Chapitre 6)</h5>
                    <p class="op-07">
                        Apprends à naviguer adroitement tes pensées et émotions, prenant le contrôle sur la négativité et cultivant un état d'esprit positif.
                    </p>
                
                    <h5>Boosters créatifs d'amour propre (Chapitre 7) </h5>
                    <p class="op-07">
                        Découvre des méthodes uniques et agréables pour intégrer l'amour propre dans ta vie quotidienne, assurant que ton voyage soit à la fois enrichissant et amusant.
                    </p>

                    <div class="line my-5"></div>
                    
                    <div class="row g-4 mt-4 mb-6">
                        <div class="col-12">
                            <h5 class="mb-2">Feuilles de travail du Workbook </h5>
                            <h5 class="mb-2">Réflexions hebdomadaires et quotidiennes </h5>
                            <h5 class="mb-2">Défi de 28 jours "Aime-toi" </h5>
                            <h5 class="mb-2">Journal de l'amour propre et journal de pleine conscience</h5>
                            <h5 class="mb-2">Suivi du sommeil et de l'humeur</h5>
                            <h5 class="mb-2">Planificateur de soins personnels et routine</h5>
                            <h5 class="mb-2">Journal de l'eau</h5>
                            <h5 class="mb-2">Liste "Défaire l'indéfait"</h5>
                            <h5 class="mb-2">Vidage cérébral</h5>
                            <h5 class="mb-2">Journal de l'eau</h5>                            
                        </div>                        
                    </div>
                    

                    <div class="line my-5"></div>

                    <p>
                        <span class="old-price fw-bold" style="font-size: 24px;">17,00 €</span>&nbsp;&nbsp;&nbsp;
                        <span class="fw-bold" style="font-size: 24px;">7.97 €</span>
                    </p>

                    <div class="d-flex justify-content-between align-items-center">
                        <a style="background-color: #c7ac97;" (click)="checkout()" *ngIf="checkoutButton === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold">Acheter
                        <i class="bi-arrow-right"></i></a>
                    <a style="background-color: #c7ac97;" *ngIf="checkoutButtonLoading === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold"><img
                            src="assets/images/loading.gif" width="20px"> </a>
                
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</section>

<!-- Version tablette -->
<section class="tablet-section">
    <div class="content-wrap">
        <div class="container">

            <div class="row gutter-50">                
                <div class="col-lg-6 portfolio-single-image">                                    
                    <div class="masonry-thumbs grid-container row row-cols-2 masonry-gap-xl mb-2" data-lightbox="gallery">
                        <a class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/1.jpg" alt="Image">
                        </a>
                        <a class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/2.jpg" alt="Image">
                        </a>
                        <a class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/3.jpg" alt="Image">
                        </a>
                        <a class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/4.jpg" alt="Image">
                        </a>
                    </div>
                    
                </div>

                
                <div class="col-lg-6 portfolio-single-content content-sticky">
                                   
                    <h2>93 pages de contenu prêt à l'emploi !</h2>
                    <p class="op-07">Es-tu prêt(e) à te lancer dans un voyage profond de découverte de soi, de guérison
                        et d'amour propre ? Notre eBook et Workbook, "Déchaînement de l'Amour Propre", te guideront à
                        travers un processus enrichissant pour libérer ton véritable potentiel et embrasser l'amour que
                        tu mérites.
                        <br></p>
                    <h5>Ce que tu trouveras à l'intérieur : </h5>
                    <p class="op-07">
                        Découvre le pouvoir transformateur de l'amour propre avec notre pack eBook et Workbook complet,
                        conçu pour t'aider à guérir ton esprit, ton corps et ton âme. Élève ton estime de soi, améliore
                        ton bien-être et acquiers les outils pour vivre une vie plus épanouissante.
                    </p>

                      <h5>Trouve ton vrai moi (Chapitre 1) : </h5>
                    <p class="op-07">
                        Gagne des insights sur la découverte de soi et apprends à te connecter avec ton moi authentique,
                        jetant les bases d'une vie pleine de sens et de bonheur.                        
                    </p>

                    <h5>Affronter tes ombres (Chapitre 2) </h5>
                    <p class="op-07">
                        Plonge profondément dans l'amour de soi en comprenant et en adressant tes peurs et doutes
                        intérieurs, te permettant de libérer les bagages émotionnels qui t'ont freinée.
                        Construire une solide fondation d'amour propre
                    </p>

                    <h5>Construire une solide fondation d'amour propre (Chapitre 3)</h5>
                    <p class="op-07">
                        Découvre des stratégies pratiques et des exercices pour cultiver l'amour propre, boostant ton
                        estime de soi et favorisant un profond sentiment de valeur personnelle.
                    </p>

                    <h5>Stratégies alternatives pour l'amour propre (Chapitre 4)</h5>
                    <p class="op-07">
                        Explore une large gamme de techniques d'amour propre, te permettant de choisir ce qui résonne le plus avec toi, créant un chemin personnalisé vers l'acceptation de soi.
                    </p>
                  
                    <h5>Psychologie de l'estime de soi (Chapitre 5)</h5>
                    <p class="op-07">
                        Comprends les complexités de l'estime de soi et la science qui se cache derrière, t'offrant une perspective plus profonde sur ton voyage vers l'amour de soi.
                    </p>
                  
                    <h5>Maîtriser tes pensées et émotions (Chapitre 6)</h5>
                    <p class="op-07">
                        Apprends à naviguer adroitement tes pensées et émotions, prenant le contrôle sur la négativité et cultivant un état d'esprit positif.
                    </p>
                
                    <h5>Boosters créatifs d'amour propre (Chapitre 7) </h5>
                    <p class="op-07">
                        Découvre des méthodes uniques et agréables pour intégrer l'amour propre dans ta vie quotidienne, assurant que ton voyage soit à la fois enrichissant et amusant.
                    </p>

                    <div class="line my-5"></div>
                    
                    <div class="row g-4 mt-4 mb-6">
                        <div class="col-12">
                            <h5 class="mb-2">Feuilles de travail du Workbook </h5>
                            <h5 class="mb-2">Réflexions hebdomadaires et quotidiennes </h5>
                            <h5 class="mb-2">Défi de 28 jours "Aime-toi" </h5>
                            <h5 class="mb-2">Journal de l'amour propre et journal de pleine conscience</h5>
                            <h5 class="mb-2">Suivi du sommeil et de l'humeur</h5>
                            <h5 class="mb-2">Planificateur de soins personnels et routine</h5>
                            <h5 class="mb-2">Journal de l'eau</h5>
                            <h5 class="mb-2">Liste "Défaire l'indéfait"</h5>
                            <h5 class="mb-2">Vidage cérébral</h5>
                            <h5 class="mb-2">Journal de l'eau</h5>                            
                        </div>                        
                    </div>
                    

                    <div class="line my-5"></div>

                    <p>
                        <span class="old-price fw-bold" style="font-size: 24px;">17,00 €</span>&nbsp;&nbsp;&nbsp;
                        <span class="fw-bold" style="font-size: 24px;">7.97 €</span>
                    </p>

                    <div class="d-flex justify-content-between align-items-center">
                        <a style="background-color: #c7ac97;" (click)="checkout()" *ngIf="checkoutButton === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold">Acheter
                        <i class="bi-arrow-right"></i></a>
                    <a style="background-color: #c7ac97;" *ngIf="checkoutButtonLoading === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold"><img
                            src="assets/images/loading.gif" width="20px"> </a>
                
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</section>

<!-- Version mobil -->
<section  class="mobile-section">
    <div class="content-wrap">
        <div class="container">
            <div class="row gutter-50">                
                <div class="col-lg-7 portfolio-single-image">                             
                    <div class="masonry-thumbs grid-container row row-cols-2 masonry-gap-xl mb-2" data-lightbox="gallery">
                        <a  class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/1.jpg" alt="Image">
                        </a>
                        <a  class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/2.jpg" alt="Image">
                        </a>
                        <a  class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/3.jpg" alt="Image">
                        </a>
                        <a  class="grid-item">
                            <img class="rounded-5" src="assets/images/ebooks/ebook01/4.jpg" alt="Image">
                        </a>
                    </div>                     
                </div>
                
                <div class="col-lg-5 portfolio-single-content content-sticky">
                                   
                    <h2>93 pages de contenu prêt à l'emploi !</h2>
                    <p class="op-07">Es-tu prêt(e) à te lancer dans un voyage profond de découverte de soi, de guérison
                        et d'amour propre ? Notre eBook et Workbook, "Déchaînement de l'Amour Propre", te guideront à
                        travers un processus enrichissant pour libérer ton véritable potentiel et embrasser l'amour que
                        tu mérites.
                        <br></p>
                    <h5>Ce que tu trouveras à l'intérieur : </h5>
                    <p class="op-07">
                        Découvre le pouvoir transformateur de l'amour propre avec notre pack eBook et Workbook complet,
                        conçu pour t'aider à guérir ton esprit, ton corps et ton âme. Élève ton estime de soi, améliore
                        ton bien-être et acquiers les outils pour vivre une vie plus épanouissante.
                    </p>

                    <h5>Trouve ton vrai moi (Chapitre 1) : </h5>
                    <p class="op-07">
                        Gagne des insights sur la découverte de soi et apprends à te connecter avec ton moi authentique,
                        jetant les bases d'une vie pleine de sens et de bonheur.                        
                    </p>

                    <h5>Affronter tes ombres (Chapitre 2) </h5>
                    <p class="op-07">
                        Plonge profondément dans l'amour de soi en comprenant et en adressant tes peurs et doutes
                        intérieurs, te permettant de libérer les bagages émotionnels qui t'ont freinée.
                        Construire une solide fondation d'amour propre
                    </p>

                    <h5>Construire une solide fondation d'amour propre (Chapitre 3)</h5>
                    <p class="op-07">
                        Découvre des stratégies pratiques et des exercices pour cultiver l'amour propre, boostant ton
                        estime de soi et favorisant un profond sentiment de valeur personnelle.
                    </p>

                    <h5>Stratégies alternatives pour l'amour propre (Chapitre 4)</h5>
                    <p class="op-07">
                        Explore une large gamme de techniques d'amour propre, te permettant de choisir ce qui résonne le plus avec toi, créant un chemin personnalisé vers l'acceptation de soi.
                    </p>
                  
                    <h5>Psychologie de l'estime de soi (Chapitre 5)</h5>
                    <p class="op-07">
                        Comprends les complexités de l'estime de soi et la science qui se cache derrière, t'offrant une perspective plus profonde sur ton voyage vers l'amour de soi.
                    </p>
                  
                    <h5>Maîtriser tes pensées et émotions (Chapitre 6)</h5>
                    <p class="op-07">
                        Apprends à naviguer adroitement tes pensées et émotions, prenant le contrôle sur la négativité et cultivant un état d'esprit positif.
                    </p>
                
                    <h5>Boosters créatifs d'amour propre (Chapitre 7) </h5>
                    <p class="op-07">
                        Découvre des méthodes uniques et agréables pour intégrer l'amour propre dans ta vie quotidienne, assurant que ton voyage soit à la fois enrichissant et amusant.
                    </p>

                    <div class="line my-5"></div>
                    
                    <div class="row g-4 mt-4 mb-6">
                        <div class="col-12">
                            <h5 class="mb-2">Feuilles de travail du Workbook </h5>
                            <h5 class="mb-2">Réflexions hebdomadaires et quotidiennes </h5>
                            <h5 class="mb-2">Défi de 28 jours "Aime-toi" </h5>
                            <h5 class="mb-2">Journal de l'amour propre et journal de pleine conscience</h5>
                            <h5 class="mb-2">Suivi du sommeil et de l'humeur</h5>
                            <h5 class="mb-2">Planificateur de soins personnels et routine</h5>
                            <h5 class="mb-2">Journal de l'eau</h5>
                            <h5 class="mb-2">Liste "Défaire l'indéfait"</h5>
                            <h5 class="mb-2">Vidage cérébral</h5>
                            <h5 class="mb-2">Journal de l'eau</h5>                            
                        </div>                        
                    </div>
                    

                    <div class="line my-5"></div>

                    <p>
                        <span class="old-price fw-bold" style="font-size: 24px;">17,00 €</span>&nbsp;&nbsp;&nbsp;
                        <span class="fw-bold" style="font-size: 24px;">7.97 €</span>
                    </p>

                    <div class="d-flex justify-content-between align-items-center">
                        <a style="background-color: #c7ac97;" (click)="checkout()" *ngIf="checkoutButton === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold">Acheter
                        <i class="bi-arrow-right"></i></a>
                    <a style="background-color: #c7ac97;" *ngIf="checkoutButtonLoading === true"
                        class="button text-center btn-block button-circle button-rounded w-50 m-0 fw-bold"><img
                            src="assets/images/loading.gif" width="20px"> </a>
                
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</section>



<app-simulator-footer></app-simulator-footer>