import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorService } from '../../../services/simulator.service';
import { SimulatorSessionv1 } from "../../../session/simulatorv1.session";
import { environment } from '../../../../environments/environment';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-bu-01-loading',
  templateUrl: './bu-01-loading.component.html',
  styleUrls: ['./bu-01-loading.component.css']
})
export class Bu01LoadingComponent implements OnInit, OnDestroy {

  session: any = {}; // Contient les données de la session utilisateur
  error: any; // Gestion des erreurs

  selectedSaleTexts: string[] = []; // Contient les textes de vente sélectionnés pour chaque étape
  currentTextIndex = 0; // Index du texte actuellement affiché
  subscription: Subscription | null = null; // Subscription pour gérer le cycle d'affichage des textes

  /**
   * Constructeur injectant les services nécessaires :
   * - Router : pour la navigation
   * - SimulatorSessionv1 : pour gérer les données de session utilisateur
   * - SimulatorService : pour appeler les API du backend liées au simulateur
   */
  constructor(
    private router: Router, 
    private simulatorSessionv1: SimulatorSessionv1, 
    private simulatorService: SimulatorService
  ) {}

  /**
   * Méthode appelée au démarrage du composant. Initialise la session,
   * lance le cycle d'affichage des textes de vente, et enregistre le rapport.
   */
  ngOnInit(): void {
    // Récupération de la session utilisateur
    this.session = this.simulatorSessionv1.getItem(environment.products.simulatorbu01.sessionId);

    // Obtient tous les textes de vente pour les réponses sélectionnées
    this.getAllSelectedResponsesSaleTexts(this.session);

    // Démarre le cycle d'affichage des textes
    this.startCycle();

    // Enregistre l'heure actuelle dans la session
    const currentDate = new Date();
    this.session.created = currentDate.getTime();
    this.simulatorSessionv1.setItem(environment.products.simulatorbu01.sessionId, this.session);

    // Vérifie que la session est valide, sinon redirige vers la première étape du simulateur
    if (this.session === null || !this.session.step2.check || !this.session.step3.check || !this.session.step4.check || 
        !this.session.step5.check || !this.session.step6.check || !this.session.step7.check || !this.session.step8.check || 
        !this.session.step9.check) {
      this.router.navigateByUrl('/business-1/simulateur/etape/1');
    } else {
      // Sauvegarde le rapport et redirige vers la page du rapport après 8 secondes
      this.simulatorService.saveReport(this.session).subscribe({
        next: (response: any) => {
          this.session.id = response.response.id;
          this.simulatorSessionv1.setItem(environment.products.simulatorbu01.sessionId, this.session);
          setTimeout(() => {
            this.ngOnDestroy();
            this.router.navigateByUrl('business-1/rapport');
          }, 8000);
        },
        error: (error: any) => {
          this.router.navigateByUrl('business-1/simulateur/email?back=error');
        }
      });
    }
  }

  /**
   * Démarre un cycle qui change le texte affiché toutes les 800 millisecondes.
   */
  startCycle() {
    this.subscription = interval(800).subscribe(() => {
      if (this.selectedSaleTexts.length > 0) {
        this.currentTextIndex = (this.currentTextIndex + 1) % this.selectedSaleTexts.length;
      }
    });
  }

  /**
   * Méthode appelée lors de la destruction du composant pour s'assurer que l'abonnement est nettoyé.
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Fonction pour obtenir tous les textes de vente des réponses sélectionnées pour toutes les étapes.
   * @param simulatorData Les données du simulateur contenant les réponses pour chaque étape.
   */
  getAllSelectedResponsesSaleTexts(simulatorData: any) {
    let selectedSaleTexts = [];

    // Parcourir chaque étape dans les données du simulateur
    for (let stepKey in simulatorData) {
      if (simulatorData.hasOwnProperty(stepKey) && simulatorData[stepKey].response && simulatorData[stepKey].responses) {
        let step = simulatorData[stepKey];
        let responseId = `response${step.response}`; // Construire l'identifiant de la réponse

        // Accéder directement à la réponse sélectionnée
        if (step.responses.hasOwnProperty(responseId)) {
          selectedSaleTexts.push(step.responses[responseId].title_bis);
        } else {
          // Si une réponse sélectionnée n'a pas de texte spécifique
          selectedSaleTexts.push('Aucune réponse trouvée');
        }
      }
    }
    
    this.selectedSaleTexts = selectedSaleTexts;
  }
}
