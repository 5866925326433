import { Component } from '@angular/core';

@Component({
  selector: 'app-products-ebook',
  templateUrl: './products-ebook.component.html',
  styleUrls: ['./products-ebook.component.css']
})
export class ProductsEbookComponent {

}
