import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
  })
  export class EbookService {
  
    noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  
    constructor(private http: HttpClient) { }
  

    checkout(data:any) {            
      return this.http.post(environment.apiBaseUrl + '/checkout/ebook',data);
    }
   
    getEbook(id: any) {
      return this.http.get(environment.apiBaseUrl + '/ebook/'+id,{
        responseType: 'blob' as 'json' // Indique que la réponse est un Blob
      });
    }

    getEbookFile(data:any) {
      return this.http.get(environment.apiBaseUrl + '/download-ebook/'+data,{
        responseType: 'blob' as 'json' // Indique que la réponse est un Blob
      });
    }

 
  }


